import { Grid } from "@mui/material";
import { contactLogo } from "assets";
import { Image, MotionWrap } from "modules/common/components";

import { Form } from "./components";

function Contact() {
  return (
    <div id="contact">
      <Grid
        container
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent={{ sm: "space-evenly" }}
        my={6}
      >
        <Grid item xs={12} md={5} textAlign="center" pl={{ xs: 1.5, md: 3 }}>
          <Image
            loading="lazy"
            effect="opacity"
            alt="contact"
            height="auto"
            src={contactLogo}
            width="95%"
            threshold={100}
            delayTime={300}
          />
        </Grid>
        <Grid item xs={12} md={5.5} px={2}>
          <Form />
        </Grid>
      </Grid>
    </div>
  );
}

export default MotionWrap(Contact);
