import { Box, Grid, Link, Stack } from "@mui/material";
import { blueShadow, Casper, Flux, Iota, Shimmer } from "assets";
import { Image, MotionWrap, SectionHeader } from "modules/common/components";
import { supportersText } from "./constants";

function Supporters() {
  const supporters = [
    { name: "Iota", image: Iota, href: "https://www.iota.org/" },
    { name: "Casper", image: Casper, href: "https://casper.network/" },
    { name: "Flux", image: Flux, href: "https://runonflux.io/" },
    { name: "Shimmer", image: Shimmer, href: "https://shimmer.network/" },
  ];

  return (
    <Grid container justifyContent="center">
      <Grid item mb={6}>
        <Stack width={{ md: "40vw" }}>
          <SectionHeader
            title={supportersText.title}
            subtitle={supportersText.subtitle}
            content={supportersText.content}
            alignItems="center"
          />
        </Stack>
      </Grid>
      <Grid container item justifyContent="center">
        <Grid container item lg={9} justifyContent="center">
          {supporters.map((supporter) => (
            <Grid key={`Supporter:${supporter.name}`} item lg={4}>
              <Box
                sx={{
                  "&:hover": {
                    background: `transparent url(${blueShadow}) no-repeat center`,
                    backgroundSize: "50%",
                    backgroundOrigin: "content-box",
                    cursor: "pointer",
                  },
                }}
              >
                <Link
                  href={supporter.href}
                  target="black"
                  rel="nofollow noindex"
                  sx={{ cursor: "inherit" }}
                >
                  <Image
                    effect="blur"
                    alt={supporter.name}
                    src={supporter.image}
                    width="100%"
                  />
                </Link>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MotionWrap(Supporters);
