import { Box, Typography } from "@mui/material";
import { privacyAndCookies } from "modules/common/components/Footer/constants";
import { useNavigate } from "react-router-dom";
import theme from "utils/theme";

export default function PrivacyAndCookies() {
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      columnGap={2}
      justifyContent="center"
      alignItems="center"
    >
      {privacyAndCookies.map((item, index) => (
        <Box key={item.name} columnGap={2} display="flex" alignItems="center">
          <Typography
            variant="subtitle1"
            fontSize={{ xs: "16px" }}
            onClick={() => navigate(item.href)}
            sx={{
              "&:hover": {
                fontWeight: "600",
                color: "#fff",
                cursor: "pointer",
              },
            }}
          >
            {item.name}
          </Typography>

          {index === 0 ? (
            <Box
              sx={{
                bgcolor: theme.palette.primary.main,
                width: "5px",
                height: "5px",
                borderRadius: "50%",
              }}
            />
          ) : null}
        </Box>
      ))}
    </Box>
  );
}
