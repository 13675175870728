import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/600.css";
import "@fontsource/luckiest-guy/400.css";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { Layout } from "modules/common/components";
import { Home } from "modules/home";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import theme from "utils/theme";
import { mainSectionBackground } from "assets";
import { PrivacyPolicy } from "modules/PrivacyPolicy";
import { TermsOfUse } from "modules/TermsOfUse";
import { useEffect } from "react";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Box
        sx={{
          background: `transparent url(${mainSectionBackground}) top repeat`,
          backgroundPositionY: -300,
          backgroundSize: { xs: "150%", md: "100%" },
          backgroundBlendMode: "soft-light",
        }}
      >
        <BrowserRouter>
          <div className="App">
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
              </Route>
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="terms-of-use" element={<TermsOfUse />} />
            </Routes>
          </div>
        </BrowserRouter>
      </Box>
    </ThemeProvider>
  );
}

export default App;
