import { Grid, IconButton, Tooltip } from "@mui/material";
import {
  DiscordFooter,
  LinkedInFooter,
  MediumFooter,
  TikTokFooter,
  TwitterFooter,
} from "modules/common/icons";
import { useState } from "react";

export default function SocialLinksButtons() {
  const [hover, setHover] = useState<number | undefined>();

  const social = [
    {
      name: "Discord",
      href: "https://discord.com/invite/JufpFYBdKG/",
      iconFooter: (
        <DiscordFooter sx={{ fontSize: "40px" }} hover={hover === 0} />
      ),
    },
    {
      name: "LinkedIn",
      href: "https://ch.linkedin.com/company/spicyfi",
      iconFooter: (
        <LinkedInFooter sx={{ fontSize: "40px" }} hover={hover === 1} />
      ),
    },
    {
      name: "Medium",
      href: "https://cryptochefs.medium.com/",
      iconFooter: (
        <MediumFooter sx={{ fontSize: "40px" }} hover={hover === 2} />
      ),
    },
    {
      name: "Twitter",
      href: "https://twitter.com/CryptonaireApp",
      iconFooter: (
        <TwitterFooter sx={{ fontSize: "40px" }} hover={hover === 3} />
      ),
    },
    {
      name: "TikTok",
      href: "https://www.tiktok.com/@cryptochefs",
      iconFooter: (
        <TikTokFooter sx={{ fontSize: "40px" }} hover={hover === 4} />
      ),
    },
  ];
  return (
    <Grid container columnGap={{ xs: 0.5, md: 3 }} item justifyContent="center">
      {social.map((platform, index) => (
        <Grid key={platform.name} item>
          <Tooltip title={platform.name}>
            <IconButton
              href={platform.href}
              target="_blank"
              rel="nofollow noindex"
              aria-label={platform.name}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(undefined)}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  transform: "scale(1.2)",
                },
              }}
            >
              {platform.iconFooter}
            </IconButton>
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  );
}
