import { Grid } from "@mui/material";
import { forBlueSticker, forGreenSticker } from "assets";
import { Image, MotionWrap } from "modules/common/components";
import { BlueTap, GreenTap } from "modules/common/icons";
import ProcessingCard from "./components/Card";
import MockupImage from "./components/MockupImage";

function Enroll() {
  return (
    <Grid container spacing={2} overflow="hidden">
      <Grid container item xs={12} justifyContent="flex-end">
        <Grid
          item
          position="relative"
          left={{ xs: "82vw", sm: 0, lg: "12vw", xl: 0 }}
        >
          <BlueTap
            sx={{
              fontSize: { xs: "230vw", md: "130vw", xl: "100vw" },
              height: "auto",
            }}
          />
        </Grid>

        <Grid
          item
          xs={8}
          sm={6}
          md={3.4}
          xl={3.2}
          position="absolute"
          mt={{ xs: "-18vw", sm: "-20vw", md: "-8vw", xl: "-9vw" }}
          mr={{ md: "10vw", xl: "12vw" }}
        >
          <Image
            src={forBlueSticker}
            alt="for blue sticker"
            width="100%"
            height="auto"
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} flexDirection={{ xs: "column", md: "row" }}>
        <Grid container item xs={12} md={6} justifyContent="center">
          <Grid item>
            <ProcessingCard />
          </Grid>
        </Grid>

        <MockupImage display={{ xs: "none", md: "flex" }} />
      </Grid>

      <Grid container item xs={12}>
        <Grid
          item
          position="relative"
          left={{ xs: "-60vw", md: "-38vw", xl: "0vw" }}
        >
          <GreenTap
            sx={{
              fontSize: { xs: "230vw", md: "140vw", xl: "100vw" },
              height: "auto",
            }}
          />
        </Grid>
        <Grid
          item
          xs={7.2}
          sm={6}
          md={3.2}
          xl={3}
          position="absolute"
          mt={{ xs: "-13vw", sm: "-9vw", md: "-4vw", xl: "-8vw" }}
          ml={{ md: "3vw", xl: 0 }}
        >
          <Image
            src={forGreenSticker}
            alt="for green sticker"
            width="100%"
            height="auto"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
export default MotionWrap(Enroll);
