import { Box, BoxProps, Divider, Grid, Link, Typography } from "@mui/material";
import { CupLine } from "modules/common/icons";
import { ReactElement } from "react";
import theme from "utils/theme";

function CreatorItem({
  title,
  reward,
  coin,
  active,
  link,
  sx,
}: {
  title: string;
  reward: ReactElement;
  coin: ReactElement;
  link: string;
  active?: boolean;
} & BoxProps) {
  return (
    <Box
      sx={{
        width: { xs: "200px", xl: "220px" },
        backgroundColor: active ? "common.black" : "#1B1D23",
        borderRadius: "16px",
        boxShadow: 1,
        alignItems: "center",
        p: 2,
        mx: 2,
        "&:hover": {
          cursor: "pointer",
        },
        ...sx,
      }}
    >
      <Link
        href={link}
        target="black"
        rel="nofollow noindex"
        sx={{ cursor: "inherit", textDecoration: "none" }}
      >
        <Grid container justifyContent="center" rowGap={2}>
          <Grid item xs={12}>
            <Box
              display="flex"
              width="100%"
              justifyContent="space-evenly"
              alignItems="center"
              bgcolor={theme.palette.background.paper}
              p="4px 16px 4px 16px"
              borderRadius="25px"
            >
              <CupLine sx={{ fontSize: { md: "1vw" }, height: "auto" }} />
              <Typography
                variant="body2"
                fontSize="0.7rem"
                textTransform="uppercase"
                textAlign="center"
              >
                {reward}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider
              flexItem
              sx={{
                backgroundColor: "grey",
                mt: 1,
              }}
            />
          </Grid>
          <Grid container item flexDirection="column" rowGap={2}>
            <Grid item xs textAlign="center">
              {coin}
            </Grid>
            <Grid item xs>
              <Typography variant="body2" textAlign="center">
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Link>
    </Box>
  );
}

CreatorItem.defaultProps = {
  active: false,
};

export default CreatorItem;
