import { alpha, createTheme } from "@mui/material";
import { red } from "@mui/material/colors";

const disabled = "rgba(255, 255, 255, 0.6)";
const lightDisabled = "rgba(255, 255, 255,0.05)";
const gray = "rgba(119, 126, 144, 1)";
const bgColor = "rgba(33, 38, 46, 1)";
const darkBgColor = "rgba(35, 38, 47, 1)";
const blue = "#3772FF";
const transparentBlue = "rgba(51, 147, 244, 0.5)";

const defaultTheme = createTheme();
let appTheme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
      light: lightDisabled,
      dark: darkBgColor,
      contrastText: gray,
    },

    info: {
      main: "#E6E8EC",
      dark: "#141416",
    },
    secondary: {
      main: "#21262E",
      light: "rgba(188, 190, 192, 1)",
      contrastText: "rgba(252, 252, 253, 1)",
      dark: transparentBlue,
    },
    background: {
      default: bgColor,
      paper: blue,
    },
    error: {
      main: red[800],
    },
    success: {
      main: "#CCFB5A",
      dark: "#CDFF57",
    },
    text: {
      secondary: disabled,
      disabled: "gray",
    },
    action: {
      disabled: lightDisabled,
      disabledBackground: lightDisabled,
    },
  },
  shape: {
    borderRadius: 30,
  },
  typography: {
    fontFamily: "'Rubik', 'Helvetica', 'Arial', sans-serif",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

appTheme = createTheme(appTheme, {
  typography: {
    h1: {
      fontSize: "4.4vw",
      fontWeight: 800,
      color: appTheme.palette.common.white,
      paddingTop: "8px",
      fontFamily: "Rubik",
      [defaultTheme.breakpoints.up("xs")]: {
        fontSize: "11vw",
      },
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: "7vw",
      },
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: "4.4vw",
      },
      [defaultTheme.breakpoints.up("xl")]: {
        fontSize: "3.5vw",
      },
    },
    h2: {
      fontWeight: 500,
      color: appTheme.palette.common.white,
      fontFamily: "Rubik",
      [defaultTheme.breakpoints.up("xs")]: {
        fontSize: "30pt",
      },
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: "2.9vw",
      },
      [defaultTheme.breakpoints.up("xl")]: {
        fontSize: "2.6vw",
      },
    },
    h3: {
      fontWeight: 500,
      color: appTheme.palette.common.white,
      fontFamily: "Rubik",
      [defaultTheme.breakpoints.up("xs")]: {
        fontSize: "16pt",
      },
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: "1.8vw",
      },
      [defaultTheme.breakpoints.up("xl")]: {
        fontSize: "1.2vw",
      },
    },
    h5: {
      fontWeight: 500,
      color: blue,
      fontFamily: "Rubik",
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: "3vw",
      },
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: "1.5vw",
      },
    },
    h4: {
      fontWeight: 500,
      color: appTheme.palette.common.white,
      paddingTop: "8px",
      fontFamily: "Rubik",
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: "2.5vw",
      },
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: "1.5vw",
      },
    },
    h6: {
      fontSize: "2.5vw",
      fontWeight: 500,
      color: appTheme.palette.info.main,
      paddingTop: "8px",
      fontFamily: "Rubik",
      [defaultTheme.breakpoints.down("md")]: {
        fontSize: "1rem",
      },
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: "1.5vw",
      },
    },
    subtitle1: {
      fontSize: "0.8vw",
      fontWeight: 400,
      fontFamily: "Rubik",
      color: appTheme.palette.info.main,
      [defaultTheme.breakpoints.up("xs")]: {
        fontSize: "6vw",
      },
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: "1.4vw",
      },
    },
    subtitle2: {
      fontWeight: 500,
      fontFamily: "Rubik",
      color: appTheme.palette.text.secondary,
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "12pt",
      },
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: "1.1vw",
      },
      [defaultTheme.breakpoints.up("xl")]: {
        fontSize: "0.9vw",
      },
    },
    body1: {
      fontWeight: 500,
      fontFamily: "Rubik",
      color: appTheme.palette.text.secondary,
      [defaultTheme.breakpoints.down("md")]: {
        fontSize: "11pt",
      },
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: "0.9vw",
      },
      [defaultTheme.breakpoints.up("xl")]: {
        fontSize: "0.7vw",
      },
    },
    body2: {
      fontWeight: 500,
      fontFamily: "Rubik",
      color: appTheme.palette.primary.main,
      fontSize: "15px",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          cursor: "default",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(33, 38, 46, 0.8)",
          overflow: "none",
          backdropFilter: "blur(43px)",
          boxShadow: "10px 10px 10px rgba(33, 38, 46, 0.5)",
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          animation: "pulse 2s infinite",
          [defaultTheme.breakpoints.down("md")]: {
            animation: "none",
          },
          "@keyframes pulse": {
            "0%": {
              boxShadow: `0 0 0 0 ${alpha(appTheme.palette.primary.main, 0.3)}`,
            },
            "100%": {
              boxShadow: `0 0 0 10px ${alpha(
                appTheme.palette.primary.main,
                0,
              )}`,
            },
          },
        },
        text: {
          animation: "none",
        },
        contained: {
          [`&.MuiButton-containedPrimary:hover`]: {
            transform: "scale(1.02)",
            backgroundColor: appTheme.palette.primary.main,
            boxShadow: "none",
            animation: "none",
          },
          [`&.MuiButton-containedPrimary:disabled`]: {
            animation: "none",
          },
        },
        outlined: {
          [`&.MuiButton-outlinedPrimary:hover`]: {
            transform: "scale(1.02)",
            backgroundColor: appTheme.palette.primary.main,
            color: appTheme.palette.info.dark,
            boxShadow: "none",
            animation: "none",
          },
          [`&.MuiButton-outlinedPrimary:disabled`]: {
            animation: "none",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          height: "auto",
          opacity: "0.4",
          background: "transparent",
          zIndex: "-9999",
          [defaultTheme.breakpoints.between("sm", "md")]: {
            width: "350px",
            height: "auto",
          },
          [defaultTheme.breakpoints.down("sm")]: {
            width: "220px",
            height: "auto",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            backgroundColor: "#292F39",
            borderRadius: "8px",
            color: "#B1B5C3",
            fieldset: {
              borderRadius: "8px",
              border: "0px solid transparent",
            },
            "&:hover fieldset": {
              border: `0.5px solid ${appTheme.palette.primary.main}`,
            },
            "&.Mui-focused fieldset": {
              border: `0.5px solid ${appTheme.palette.primary.main}`,
            },
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundFilter: "blur(50px)",
          backgroundColor: appTheme.palette.background.default,
          overflowY: "auto",
          overflowX: "hidden",
          "&::-webkit-scrollbar": {
            width: "0.1rem",
            "&:hover": {
              width: "0.8rem",
            },
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: `inset 0 0 100px ${appTheme.palette.background.default}`,
            webkitBoxShadow: `inset 0 0 100px ${appTheme.palette.background.default}`,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: appTheme.palette.info.light,
            borderRadius: "0.3rem",
          },
        },
      },
    },
  },
});
const theme = appTheme;
export default theme;
