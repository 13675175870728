/* eslint-disable react/jsx-props-no-spreading */
import { SvgIcon, SvgIconProps } from "@mui/material";

export default function ArrowLeft(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 14 9" {...props}>
      <g
        id="icons_Arrow_Left_2_Line"
        data-name="icons/Arrow Left 2/Line"
        transform="translate(-5 -7)"
      >
        <path
          id="vector_Stroke_"
          data-name="vector (Stroke)"
          d="M9.091-.265A1,1,0,0,0,10.5-.322l3.231-3.5a1,1,0,0,0,0-1.357L10.5-8.678a1,1,0,0,0-1.413-.057,1,1,0,0,0-.057,1.413L10.716-5.5H1a1,1,0,0,0-1,1,1,1,0,0,0,1,1h9.716L9.034-1.678A1,1,0,0,0,9.091-.265Z"
          transform="translate(19 7) rotate(180)"
          fill="#777e90"
        />
      </g>
    </SvgIcon>
  );
}
