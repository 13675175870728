import { Props } from "modules/common/components/SectionHeader/SectionHeader";
import { Trans } from "react-i18next";

export const contactText: Props = {
  title: {
    text: <Trans i18nKey="contact.title" />,
    variant: "h5",
    textAlign: "left",
  },
  subtitle: {
    text: <Trans i18nKey="contact.subTitle" />,
    variant: "h2",
    textAlign: "left",
  },

  content: {
    text: <Trans i18nKey="contact.content" />,
    variant: "subtitle2",
    textAlign: "left",
  },
};
