/* eslint-disable react/jsx-props-no-spreading */
import { SvgIcon, SvgIconProps } from "@mui/material";

export default function Star(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 65.082 65" {...props}>
      <g
        id="Group_24806"
        data-name="Group 24806"
        transform="translate(0 -0.091)"
      >
        <rect
          id="Rectangle_1294"
          data-name="Rectangle 1294"
          width="8"
          height="65"
          rx="2"
          transform="translate(28.871 0.091)"
          fill="#cdff57"
        />
        <rect
          id="Rectangle_1295"
          data-name="Rectangle 1295"
          width="8.221"
          height="64.949"
          rx="2"
          transform="matrix(0.017, -1, 1, 0.017, 0, 36.018)"
          fill="#cdff57"
        />
        <rect
          id="Rectangle_1296"
          data-name="Rectangle 1296"
          width="8.221"
          height="64.949"
          rx="2"
          transform="translate(13.4 59.028) rotate(-137)"
          fill="#cdff57"
        />
        <rect
          id="Rectangle_1297"
          data-name="Rectangle 1297"
          width="8.221"
          height="64.949"
          rx="2"
          transform="translate(7.387 11.528) rotate(-43)"
          fill="#cdff57"
        />
      </g>
    </SvgIcon>
  );
}
