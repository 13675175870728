/* eslint-disable import/no-unresolved */
import CookieConsent from "react-cookie-consent";
import { Typography, Link, Grid, useMediaQuery } from "@mui/material";
import { CookiesIcon } from "modules/common/icons";
import theme from "utils/theme";
import CloseIcon from "modules/common/icons/CloseIcon/CloseIcon";
import { useState } from "react";

function Cookies() {
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [closeIsActive, setCloseIsActive] = useState(false);

  const [hover, setHover] = useState(false);
  return (
    <CookieConsent
      enableDeclineButton
      declineButtonText={
        <CloseIcon
          activeIcon={closeIsActive}
          sx={{ fontSize: "1.5rem" }}
          onMouseEnter={() => setCloseIsActive(true)}
          onMouseLeave={() => setCloseIsActive(false)}
        />
      }
      location="bottom"
      buttonText="Accept cookies"
      cookieName="Cryptonaire Cookies"
      style={{
        width: mobile ? "320px" : "250px",
        height: "fit-content",
        background: "#010101 0% 0% no-repeat padding-box",
        border: "1px solid #4E5158",
        borderRadius: "16px",
        color: "#464445",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-end",
        zIndex: "99999999999",
        marginLeft: mobile ? 0 : "3%",
        marginBottom: mobile ? 0 : "2%",
      }}
      buttonStyle={{
        position: "relative",
        left: mobile ? "-13%" : 9,
        width: "200px",
        height: "40px",
        color: hover ? theme.palette.info.dark : theme.palette.primary.main,
        backgroundColor: hover ? theme.palette.primary.main : "transparent",
        border: "1px solid #BBBBBC",
        borderRadius: "30px",
        fontSize: "0.7rem",
        fontFamily: "Rubik",
        fontWeight: 600,
      }}
      customButtonProps={{
        onMouseOver: () => setHover(true),
        onMouseLeave: () => setHover(false),
      }}
      declineButtonStyle={{
        backgroundColor: "transparent",
        position: "relative",
        top: mobile ? "-225px" : -160,
        left: mobile ? "80%" : "75%",
      }}
      expires={150}
      contentStyle={{
        marginBottom: mobile ? -100 : -160,
      }}
    >
      <Grid
        container
        flexDirection="column"
        rowGap={1}
        position="relative"
        top={40}
      >
        <Grid item xs={12} textAlign="center" mt={-3}>
          <CookiesIcon sx={{ fontSize: "7rem", height: "auto" }} />
        </Grid>
        <Grid item textAlign="center" mt={-2}>
          <Typography variant="body1" color="common.white">
            Our website use cookies
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            fontSize="11px"
            fontWeight="400"
            textAlign="center"
          >
            Our website use cookies. By continuing, we assume your permission to
            deploy cookies as detailed in our{" "}
            <Link
              underline="none"
              color={theme.palette.success.dark}
              target="blank"
              href="https://docs.google.com/document/d/1_HireRav5WevLQ9bL2jHCg6OLIYAFrjgLO9Yp1dEfWE/edit"
            >
              privacy policy
            </Link>
            .
          </Typography>
        </Grid>
      </Grid>
    </CookieConsent>
  );
}
export default Cookies;
