import { Grid } from "@mui/material";
import { footerBackground } from "assets";
import DownloadApp from "../DownloadApp/DownloadApp";
import { CopyRights, Logo, SocialLinks } from "./components";

type TFooter = {
  showTextLinks: boolean;
};

function Footer({ showTextLinks }: TFooter) {
  return (
    <Grid
      py={7}
      container
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        background: `transparent url(${footerBackground}) no-repeat center`,
        backgroundSize: "cover",
      }}
      rowGap={4}
    >
      <Grid item>
        <Logo />
      </Grid>
      <Grid item>
        <DownloadApp />
      </Grid>
      <Grid item xs={12}>
        <SocialLinks showTextLinks={showTextLinks} />
      </Grid>

      <Grid item xs={12}>
        <CopyRights />
      </Grid>
    </Grid>
  );
}
export default Footer;
