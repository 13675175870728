/* eslint-disable react/jsx-props-no-spreading */
import { SvgIcon, SvgIconProps } from "@mui/material";

export default function FlashCards(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24.338 20.633" {...props}>
      <g
        id="Flash_Cards"
        data-name="Flash Cards"
        transform="translate(1.689 -1.879) rotate(6.024)"
      >
        <g id="Group_16363" data-name="Group 16363" transform="translate(0 0)">
          <path
            id="Path_41974"
            data-name="Path 41974"
            d="M1.665.936,5.185,0V1.552l-3.137.834a.752.752,0,0,0-.531.918l3.054,11.6a.75.75,0,0,0,.918.533L7.9,14.8a2.667,2.667,0,0,0,.275.028h5.293a2.217,2.217,0,0,1-.38.143l-7.22,1.92a2.24,2.24,0,0,1-2.739-1.6L.074,3.689A2.254,2.254,0,0,1,1.665.935Z"
            transform="translate(2.487 3.976)"
            fill="#777e90"
          />
          <path
            id="Subtraction_4"
            data-name="Subtraction 4"
            d="M10.449,16.507H2.239a2.235,2.235,0,0,1-1.583-.66A2.259,2.259,0,0,1,0,14.256V2.25A2.259,2.259,0,0,1,.656.66,2.235,2.235,0,0,1,2.239,0h8.078L8.923.467A.446.446,0,0,0,8.616.9a.446.446,0,0,0,.307.429l.524.175H2.239a.745.745,0,0,0-.528.22.754.754,0,0,0-.219.531V14.256a.749.749,0,0,0,.747.749h8.21a.744.744,0,0,0,.527-.219.753.753,0,0,0,.219-.53V3.029l.288.871a.447.447,0,0,0,.851,0l.352-1.062V14.258a2.259,2.259,0,0,1-.657,1.589,2.236,2.236,0,0,1-1.582.659Z"
            transform="translate(8.417 1.549)"
            fill="#777e90"
          />
          <path
            id="Path_41992"
            data-name="Path 41992"
            d="M2.22.154a.224.224,0,0,1,.426,0l.481,1.451a.225.225,0,0,0,.142.142l1.444.484a.226.226,0,0,1,0,.429L3.27,3.144a.225.225,0,0,0-.142.142L2.646,4.738a.224.224,0,0,1-.426,0L1.739,3.286A.225.225,0,0,0,1.6,3.144L.153,2.662a.226.226,0,0,1,0-.429L1.6,1.744A.225.225,0,0,0,1.739,1.6Z"
            transform="translate(17.895 0)"
            fill="#777e90"
          />
          <path
            id="Path_41976"
            data-name="Path 41976"
            d="M3.716,7.859a5.957,5.957,0,0,0-1.54.889,3.121,3.121,0,0,0-.943,1.162c-.14.282-.059,1.028,1.446.635C7.1,9.391,18.883,0,18.883,0S8.761,9.9,4.12,11.557C.3,12.921-.551,11.22.3,9.467a3.643,3.643,0,0,1,.666-.889A12.429,12.429,0,0,1,2.255,7.59a13.338,13.338,0,0,1,1.213-.744Z"
            transform="translate(0 4.031)"
            fill="#777e90"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
