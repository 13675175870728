/* eslint-disable react/jsx-props-no-spreading */
import { SvgIcon, SvgIconProps } from "@mui/material";

export default function AppleSmallWhite(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 14.389 17.27" {...props}>
      <g
        id="whiteIcon_ionic-logo-apple"
        data-name="whiteIcon ionic-logo-apple"
        transform="translate(-6.748)"
      >
        <path
          id="Path_53117"
          data-name="Path 53117"
          d="M17.432,10.821c-1.511,0-2.149.742-3.2.742-1.079,0-1.9-.737-3.21-.737A4.362,4.362,0,0,0,7.5,13c-1.218,1.933-1.012,5.575.962,8.677.706,1.111,1.65,2.356,2.886,2.369h.022c1.075,0,1.394-.724,2.873-.733h.022c1.457,0,1.749.728,2.819.728h.022c1.236-.013,2.23-1.394,2.936-2.5a12.3,12.3,0,0,0,1.088-2.1,3.847,3.847,0,0,1-.49-6.874,4.228,4.228,0,0,0-3.215-1.753Z"
          transform="translate(0 -6.779)"
          fill="#fff"
        />
        <path
          id="Path_53118"
          data-name="Path 53118"
          d="M21,4.5a3.932,3.932,0,0,0-2.563,1.421A3.576,3.576,0,0,0,17.6,8.654h.072A3.338,3.338,0,0,0,20.179,7.3,3.829,3.829,0,0,0,21,4.5Z"
          transform="translate(-3.898 -4.5)"
          fill="#fff"
        />
      </g>
    </SvgIcon>
  );
}
