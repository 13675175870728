/* eslint-disable react/no-unescaped-entities */

import { Grid, Link, Typography, useMediaQuery } from "@mui/material";
import { BlueLogo } from "assets";
import {
  Image,
  OtherContainer,
  SectionHeader,
} from "modules/common/components";
import { Props } from "modules/common/components/SectionHeader/SectionHeader";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import theme from "utils/theme";

const warningText = [
  {
    content: <Trans i18nKey="privacy.warningLine1" />,
  },
  {
    content: <Trans i18nKey="privacy.warningLine2" />,
  },
  {
    content: <Trans i18nKey="privacy.warningLine2" />,
  },
];
const title: Props = {
  title: {
    text: <Trans i18nKey="privacy.title" />,
    variant: "h3",
    textAlign: "center",
  },
  subtitle: {
    text: <Trans i18nKey="privacy.subTitle" />,
    variant: "h6",
    textAlign: "center",
  },

  content: {
    text: <Trans i18nKey="privacy.dateOfIssuance" />,
    variant: "body1",
  },
};

const definitions = [
  {
    title: "privacy.consent",
    value: "Consent",
  },
  {
    title: "privacy.dataControllers",
    value: "Data Controller(s)",
  },
  {
    title: "privacy.dataProtection",
    value: "Data Protection Laws",
  },
  {
    title: "privacy.dataProcessor",
    value: "Data Processor",
  },
  {
    title: "privacy.dataSubjects",
    value: "Data Subject(s)",
  },
  {
    title: "privacy.disclosure",
    value: "Disclosure",
  },
  {
    title: "privacy.eea",
    value: "EEA",
  },
  {
    title: "privacy.newsLetter",
    value: "News Letter",
  },
  {
    title: "privacy.personalData",
    value: "Personal Data ",
  },
  {
    title: "privacy.processing",
    value: "Processing",
  },
  {
    title: "privacy.standardContractualClauses",
    value: "Standard Contractual Clauses",
  },
  {
    title: "privacy.thirdParty",
    value: "Third-party(-ies)",
  },
];
const principles = [
  {
    title: "privacy.principles.transparency",
    description: "privacy.principles.transparencyContent",
    description2: "",
  },
  {
    title: "privacy.principles.restriction",
    description: "privacy.principles.restrictionContent",
    description2: "",
  },
  {
    title: "privacy.principles.fairness",
    description: "privacy.principles.fairnessContent",
    description2: "",
  },
  {
    title: "privacy.principles.consentOfSubject",
    description: "privacy.principles.consentContent1",
    description2: "privacy.principles.consentContent2",
  },
  {
    title: "privacy.principles.accuracy",
    description: "privacy.principles.accuracyContent",
    description2: "",
  },
];
const restOfPrinciples = [
  {
    title: "privacy.collectedData.title",
    content: "privacy.collectedData.content",
  },
  {
    title: "privacy.useOfData.title",
    content: "privacy.useOfData.content",
  },
  {
    title: "privacy.storageOfYourPersonalData.title",
    content: "privacy.storageOfYourPersonalData.content",
  },
  {
    title: "privacy.collectedData.title",
    content: "privacy.collectedData.content",
  },
  {
    title: "privacy.thirdPartyDisclosure.title",
    content: "privacy.thirdPartyDisclosure.content",
  },
  {
    title: "privacy.security.title",
    content: "privacy.security.content",
  },
  {
    title: "privacy.access.title",
    content: "privacy.access.content",
  },
  {
    title: "privacy.dataPortability.title",
    content: "privacy.dataPortability.content",
  },
  {
    title: "privacy.privacyByDesign.title",
    content: "privacy.privacyByDesign.content",
  },
  {
    title: "privacy.contactComplain.title",
    content: "privacy.contactComplain.content",
  },
  {
    title: "privacy.changesToPrivacy.title",
    content: "privacy.changesToPrivacy.content",
  },
  {
    title: "privacy.dataController.title",
    content: "privacy.dataController.content",
  },
  {
    title: "privacy.links.title",
    content: "privacy.links.content",
  },
  {
    title: "privacy.jurisdiction.title",
    content: "privacy.jurisdiction.content",
  },
  {
    title: "privacy.contact.title",
    content: "privacy.contact.content",
  },
];
function PrivacyPolicy() {
  const { t } = useTranslation();
  const mediaQueryMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container>
      <OtherContainer
        backDest="/"
        backTitle="Go Back"
        forwardDest="/terms-of-use"
        forwardTitle="Terms of use"
      >
        <Grid
          container
          py={8}
          px={{ xs: 1, md: 15 }}
          flexDirection="column"
          rowGap={5}
        >
          <Grid
            container
            item
            xs={12}
            flexDirection="column"
            textAlign="center"
            spacing={1}
          >
            <Grid item xs={12} columnGap={2}>
              {warningText.map((item) => (
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    color="common.white"
                    fontWeight="400"
                  >
                    {item.content}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={3} md={1} alignSelf="center">
            <Image src={BlueLogo} style={{ width: "100%" }} />
          </Grid>
          <Grid item>
            <SectionHeader
              title={title.title}
              subtitle={title.subtitle}
              content={title.content}
              alignItems="center"
            />
          </Grid>
          <Grid item>
            <Typography variant="body1" fontWeight="400">
              <Trans
                i18nKey="privacy.securityAndProtection"
                values={{
                  company: '("Company")',
                  application: '("Application")',
                  users: '("Users")',
                  cryptonaireTerms: "https://cryptonaire.app/terms-of-use",
                }}
                components={{
                  bold: <strong />,
                  italic: <i />,
                  a: (
                    <Link
                      target="_blank"
                      href="https://cryptonaire.app/terms-of-use"
                      sx={{ cursor: "pointer" }}
                    />
                  ),
                }}
              />
            </Typography>
          </Grid>

          <Grid container item xs={12} flexDirection="column" spacing={1}>
            <Grid item xs={12} mb={2}>
              <Typography variant="h5" fontSize={{ xs: "1rem", md: "1.5rem" }}>
                1.{t("privacy.definitions")}
              </Typography>
            </Grid>
            {definitions.map((item, index) => (
              <Grid
                item
                key={`definition of ${item.value}`}
                display="flex"
                flexDirection="row"
              >
                <Typography mr={1}>{index + 1}.</Typography>
                <Typography
                  variant="body1"
                  fontWeight="400"
                  display="flex"
                  flexDirection="row"
                >
                  <Trans
                    i18nKey={item.title}
                    values={{
                      title: item.value,
                    }}
                    components={{
                      bold: !mediaQueryMd ? (
                        <strong />
                      ) : (
                        <Typography
                          fontWeight="500"
                          noWrap
                          color="common.white"
                          sx={{ minWidth: "fit-content" }}
                        />
                      ),
                    }}
                  />
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Grid container item xs={12} flexDirection="column" spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5" fontSize={{ xs: "1rem", md: "1.5rem" }}>
                2.{t("privacy.scope.title")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="400">
                <Trans
                  i18nKey="privacy.scope.content"
                  components={{
                    br: <br />,
                  }}
                />
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" fontSize={{ xs: "1rem", md: "1.5rem" }}>
                3.{t("privacy.acceptance.title")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="400">
                <Trans
                  i18nKey="privacy.acceptance.content"
                  components={{
                    typography: <Typography fontWeight="400" ml={3} />,
                  }}
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" fontSize={{ xs: "1rem", md: "1.5rem" }}>
                4.{t("privacy.principles.title")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="400">
                <Trans
                  i18nKey="privacy.principles.legend"
                  components={{
                    br: <br />,
                  }}
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {principles.map((item) => (
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      display="flex"
                      flexDirection="row"
                    >
                      <Trans
                        i18nKey={item.title}
                        components={{
                          bold: (
                            <Typography
                              fontWeight="500"
                              noWrap
                              color="common.white"
                              sx={{
                                minWidth: "fit-content",
                                ml: { xs: 0, md: 4 },
                              }}
                            />
                          ),
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" fontWeight="400" ml={6}>
                      <Trans
                        i18nKey={item.description}
                        components={{
                          bold: <strong />,
                          typography: !mediaQueryMd ? (
                            <p />
                          ) : (
                            <Typography
                              noWrap
                              sx={{ minWidth: "fit-content", ml: 2 }}
                            />
                          ),
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {item.description2 ? (
                      <Typography variant="body1" fontWeight="400" ml={6}>
                        <Trans
                          i18nKey={item.description2}
                          components={{
                            bold: <strong />,
                            typography: !mediaQueryMd ? (
                              <p />
                            ) : (
                              <Typography
                                fontWeight="400"
                                noWrap
                                sx={{ minWidth: "fit-content", ml: 2 }}
                              />
                            ),
                          }}
                        />
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              ))}
            </Grid>
            {restOfPrinciples.map((item) => (
              <Grid container item xs={12}>
                <Grid item key={item.title} xs={12}>
                  <Typography
                    variant="h5"
                    fontSize={{ xs: "1rem", md: "1.5rem" }}
                  >
                    {t(item.title)}
                  </Typography>
                </Grid>
                <Grid item ml={5} xs={12}>
                  <Typography variant="body1" fontWeight="400">
                    <Trans
                      i18nKey={item.content}
                      components={{
                        br: <br />,
                        bold: <strong />,
                        typography: (
                          <Typography
                            fontWeight="500"
                            noWrap
                            color="common.white"
                            sx={{ minWidth: "fit-content", ml: 4 }}
                          />
                        ),
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </OtherContainer>
    </Grid>
  );
}

export default PrivacyPolicy;
