import { appleStoreButton, playStoreButton } from "assets";
import { APP_LINK_APPLE_STORE, APP_LINK_GOOGLE_PLAY } from "../constants";

export const downloadButtons = [
  {
    title: "App store",
    icon: appleStoreButton,
    link: APP_LINK_APPLE_STORE,
    type: "ios",
  },
  {
    title: "Play store",
    icon: playStoreButton,
    link: APP_LINK_GOOGLE_PLAY,
    type: "android",
  },
];
