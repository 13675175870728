/* eslint-disable react/jsx-props-no-spreading */
import { SvgIcon, SvgIconProps } from "@mui/material";

export default function CupLine(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 130 130" {...props}>
      <defs>
        <radialGradient
          id="radial-gradient"
          cx="0.5"
          cy="0.5"
          r="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#cdff57" />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
      </defs>
      <g
        id="Group_25469"
        data-name="Group 25469"
        transform="translate(-117.999 -55.755)"
      >
        <circle
          id="Ellipse_937"
          data-name="Ellipse 937"
          cx="65"
          cy="65"
          r="65"
          transform="translate(117.999 55.755)"
          opacity="0.35"
          fill="url(#radial-gradient)"
        />
        <g
          id="Group_25468"
          data-name="Group 25468"
          transform="translate(155.245 92.286)"
        >
          <path
            id="Path_53149"
            data-name="Path 53149"
            d="M34.6,29.508a78.335,78.335,0,0,1-8.528-10.593c-1.759-2.753-3.327-5.851-5.2-7.763a13.177,13.177,0,0,1-3.289-5.545C13.83,6.678,11.229,7.6,7.864,11.114c-23.442,24.169,10.516,61.493,36.6,36.941,1.912-1.8,1.224-3.977,2.371-5.927C43.2,37.921,38.419,33.6,34.6,29.508Z"
            fill="#abd644"
          />
          <path
            id="Path_53150"
            data-name="Path 53150"
            d="M44.462,48.055c-24.169,19.618-57.821-11.549-36.6-36.98.344-.382.727-.8,1.109-1.186A24.089,24.089,0,0,1,24.5,2.471a.941.941,0,0,1,.994.88,5.6,5.6,0,0,0,5.086,5.66.752.752,0,0,1,.612.956,10.791,10.791,0,0,0-.421,3.174,10.567,10.567,0,0,0,10.4,10.287,10.793,10.793,0,0,0,3.327-.5.722.722,0,0,1,.956.574c.688,3.748,4.092,3.059,5.7,2.524a.832.832,0,0,1,1.071.574C55.361,38.036,44.462,48.055,44.462,48.055Z"
            fill="#cdff57"
          />
          <path
            id="Path_53151"
            data-name="Path 53151"
            d="M28.055,23.887a4.812,4.812,0,0,1-5.124,4.742,4.812,4.812,0,0,1-5.124-4.742,5.124,5.124,0,1,1,10.249,0Z"
            fill="#364513"
          />
          <path
            id="Path_53152"
            data-name="Path 53152"
            d="M44.155,36.239a3.933,3.933,0,0,1-3.939,3.939,3.345,3.345,0,0,1-3.671-3.48c0-2.18,1.491-4.436,3.671-4.436A3.966,3.966,0,0,1,44.155,36.239Z"
            fill="#364513"
          />
          <path
            id="Path_53153"
            data-name="Path 53153"
            d="M14.71,31.42A2.606,2.606,0,0,1,11.8,34.059c-1.683,0-3.136-.956-3.136-2.639a3.021,3.021,0,1,1,6.042,0Z"
            fill="#364513"
          />
          <path
            id="Path_53154"
            data-name="Path 53154"
            d="M23.963,41.669a1.377,1.377,0,1,1-1.377-1.377A1.371,1.371,0,0,1,23.963,41.669Z"
            fill="#364513"
          />
          <path
            id="Path_53155"
            data-name="Path 53155"
            d="M14.632,15.244a1.53,1.53,0,1,0-1.53-1.53A1.53,1.53,0,0,0,14.632,15.244Z"
            fill="#364513"
          />
          <path
            id="Path_53156"
            data-name="Path 53156"
            d="M42.28,4.536c.153,1.836-1.453,3.327-3.595,3.327a3.534,3.534,0,0,1-3.671-3.671A3.36,3.36,0,0,1,38.609.865,4.185,4.185,0,0,1,42.28,4.536Z"
            fill="#cdff57"
          />
          <path
            id="Path_53157"
            data-name="Path 53157"
            d="M39.528,7.137a3.887,3.887,0,0,0,2.218-.65,3.734,3.734,0,0,1-3.021,1.377A3.524,3.524,0,0,1,35.054,4.23,3.614,3.614,0,0,1,36.622,1.4a3.894,3.894,0,0,0-.765,2.1A3.615,3.615,0,0,0,39.528,7.137Z"
            fill="#678226"
          />
          <path
            id="Path_53158"
            data-name="Path 53158"
            d="M42.09,14.938a2.608,2.608,0,0,1-2.295,2.371,2.04,2.04,0,0,1-2.1-2.218,2.343,2.343,0,0,1,2.294-2.371A2.058,2.058,0,0,1,42.09,14.938Z"
            fill="#cdff57"
          />
          <path
            id="Path_53159"
            data-name="Path 53159"
            d="M40.252,16.773a2.718,2.718,0,0,0,1.3-.5,2.564,2.564,0,0,1-1.8,1.032,2.06,2.06,0,0,1-2.1-2.218,2.408,2.408,0,0,1,.956-1.912,2.473,2.473,0,0,0-.459,1.377A2.039,2.039,0,0,0,40.252,16.773Z"
            fill="#678226"
          />
          <path
            id="Path_53160"
            data-name="Path 53160"
            d="M57.73,15.894a2.988,2.988,0,0,1-2.715,2.753,2.441,2.441,0,0,1-2.486-2.6,2.759,2.759,0,0,1,2.715-2.753A2.418,2.418,0,0,1,57.73,15.894Z"
            fill="#cdff57"
          />
          <path
            id="Path_53161"
            data-name="Path 53161"
            d="M55.588,18.074a2.718,2.718,0,0,0,1.53-.612,2.779,2.779,0,0,1-2.1,1.185,2.441,2.441,0,0,1-2.486-2.6,2.941,2.941,0,0,1,1.109-2.256,2.731,2.731,0,0,0-.535,1.644A2.452,2.452,0,0,0,55.588,18.074Z"
            fill="#678226"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
