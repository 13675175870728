import { createContext, useContext } from "react";

/**
 * @type {React.Context<unknown>}
 */
export const FirebaseContext = createContext({
  fbApp: undefined,
  fbAnalytics: undefined,
  fbAnalyticsLogEvent: () => undefined,
  fbAnalyticsSetUserId: () => undefined,
});

/**
 * @returns {*}
 */
export function useFirebase() {
  return useContext(FirebaseContext);
}
