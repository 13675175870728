import { Grid, Typography } from "@mui/material";
import { AndroidQrCode, QrCodeForiOS, ScanMe } from "modules/common/icons";
import React from "react";
import { useTranslation } from "react-i18next";

type TQrCode = {
  buttonType: "android" | "ios";
};

function QrCode({ buttonType }: TQrCode) {
  const { t } = useTranslation();
  return (
    <Grid container justifyContent="center" rowGap={2}>
      <Grid item display="flex" alignItems="center">
        <ScanMe
          sx={{
            fontSize: "1.2vw",
            height: "auto",
            mr: 1,
          }}
        />
      </Grid>
      <Grid item>
        <Typography variant="body2" fontWeight="400">
          {t("about.scanMe")}
        </Typography>
      </Grid>
      {buttonType === "android" ? (
        <Grid container item justifyContent="center">
          <Grid item xs={12} textAlign="center">
            <AndroidQrCode
              sx={{
                fontSize: { xs: "10.2rem", xl: "12.2rem" },
                bgcolor: "common.white",
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container item justifyContent="center" rowGap={2}>
          <Grid item xs={12} textAlign="center">
            <QrCodeForiOS
              sx={{
                fontSize: { xs: "10.2rem", xl: "12.2rem" },
                bgcolor: "common.white",
              }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
export default QrCode;
