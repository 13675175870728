import { Button, Grid, IconButton, Typography } from "@mui/material";
import { Logo } from "modules/common/icons";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import theme from "utils/theme";

type TMobileTopBar = {
  onMenuClicked: () => void;
  onGetAppClicked: () => void;
};

export default function MobileTopBar({
  onMenuClicked,
  onGetAppClicked,
}: TMobileTopBar) {
  const { t } = useTranslation();

  return (
    <Grid
      container
      display={{ xs: "flex", lg: "none" }}
      alignItems="stretch"
      justifyContent="space-between"
    >
      <Grid
        item
        xs={6}
        pl={2}
        borderRight={`1px solid ${theme.palette.secondary.light}`}
      >
        <Logo
          sx={{
            alignSelf: "center",
            fontSize: { xs: "30vw", sm: "25vw", mg: "20vw" },
            maxHeight: "50px",
            animation: `1s ease-in running`,
          }}
        />
      </Grid>
      <Grid
        container
        item
        xs={4}
        borderRight={`1px solid ${theme.palette.secondary.light}`}
        alignItems="center"
      >
        <Grid item xs>
          <Button
            onClick={onGetAppClicked}
            fullWidth
            sx={{ animation: "none" }}
          >
            <Typography
              fontSize={{ xs: "4vw", sm: "3vw", md: "2vw" }}
              variant="body1"
              textTransform="none"
            >
              {t("about.getTheApp")}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid container item xs={2} alignItems="center">
        <Grid item xs textAlign="center">
          <IconButton onClick={onMenuClicked}>
            <MenuIcon color="info" />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
