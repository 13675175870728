/* eslint-disable react/jsx-props-no-spreading */
import { SvgIcon, SvgIconProps } from "@mui/material";

export default function PhoneIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 11.5 19.462" {...props}>
      <path
        id="Icon_material-phone-iphone"
        data-name="Icon material-phone-iphone"
        d="M16.788,1.5H9.712A2.212,2.212,0,0,0,7.5,3.712V18.75a2.212,2.212,0,0,0,2.212,2.212h7.077A2.212,2.212,0,0,0,19,18.75V3.712A2.212,2.212,0,0,0,16.788,1.5ZM13.25,20.077a1.327,1.327,0,1,1,1.327-1.327A1.325,1.325,0,0,1,13.25,20.077Zm3.981-3.538H9.269V4.154h7.962Z"
        transform="translate(-7.5 -1.5)"
        fill="#141416"
      />
    </SvgIcon>
  );
}
