export const privacyAndCookies = [
  {
    name: "Privacy policy",
    href: "privacy-policy",
  },
  {
    name: "Terms of use",
    href: "terms-of-use",
  },
];
