import { Divider, Grid, GridProps } from "@mui/material";
import { Logo } from "modules/common/icons";
import React from "react";

export default function LogoSection({ ...props }: GridProps) {
  return (
    <Grid
      container
      item
      md={8}
      alignItems="center"
      justifyContent="space-around"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Grid item md={3} xl={2}>
        <Divider sx={{ bgcolor: "text.secondary" }} />
      </Grid>
      <Grid item textAlign="center">
        <Logo
          sx={{
            fontSize: { xs: "15vw", lg: "12vw", xl: "10vw" },
            height: "auto",
          }}
        />
      </Grid>
      <Grid item md={3} xl={2}>
        <Divider sx={{ bgcolor: "text.secondary" }} />
      </Grid>
    </Grid>
  );
}
