import { Props } from "modules/common/components/SectionHeader/SectionHeader";
import { Trans } from "react-i18next";

export const supportersText: Props = {
  title: {
    text: <Trans i18nKey="supporters.title" />,
    variant: "h5",
    textAlign: "center",
  },
  subtitle: {
    text: <Trans i18nKey="supporters.subTitle" />,
    variant: "h2",
    textAlign: "center",
  },

  content: {
    text: "",
    variant: "h5",
  },
};
