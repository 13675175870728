import { SvgIcon } from "@mui/material";
import { TSocialIcons } from "../types";

export default function LinkedInFooter({ hover, ...props }: TSocialIcons) {
  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <rect
        id="ig_style_"
        data-name="ig (style)"
        width="32"
        height="32"
        rx="16"
        fill={hover ? "#e6e8ec" : "#141416"}
      />
      <path
        id="Icon_awesome-linkedin"
        data-name="Icon awesome-linkedin"
        d="M11.53,2.25H.884A.892.892,0,0,0,0,3.145V13.771a.892.892,0,0,0,.884.895H11.53a.892.892,0,0,0,.887-.895V3.145A.892.892,0,0,0,11.53,2.25ZM3.753,12.893H1.913V6.97h1.84ZM2.832,6.158A1.067,1.067,0,1,1,3.9,5.091,1.067,1.067,0,0,1,2.832,6.158Zm7.819,6.735H8.811V10.011c0-.687-.014-1.571-.956-1.571-.959,0-1.106.748-1.106,1.521v2.933H4.907V6.97H6.673v.809H6.7A1.938,1.938,0,0,1,8.44,6.823c1.863,0,2.209,1.227,2.209,2.824Z"
        transform="translate(10 7.828)"
        fill={hover ? "#141416" : "#e6e8ec"}
      />
    </SvgIcon>
  );
}
