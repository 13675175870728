import Dialog from "@mui/material/Dialog";
import { Transition } from "modules/common/components/Transition/Transition";
import theme from "utils/theme";
import { MobileDialog } from "./components";

export interface IDownloadDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function DownloadDialog({
  open,
  onClose,
}: IDownloadDialogProps) {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      TransitionComponent={Transition}
      BackdropProps={{
        sx: {
          bgcolor: theme.palette.primary.light,
        },
      }}
      PaperProps={{
        sx: {
          border: `0.5px solid ${theme.palette.text.secondary}`,
          borderRadius: "16px",
          bgcolor: "common.black",
        },
      }}
    >
      <MobileDialog onClose={onClose} />
    </Dialog>
  );
}
