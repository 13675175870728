import { Button, Grid, IconButton, Typography } from "@mui/material";
import { BlueLogo } from "assets";
import {
  APP_LINK_APPLE_STORE,
  APP_LINK_GOOGLE_PLAY,
} from "modules/common/constants/constants";
import Image from "modules/common/components/Image/image";
import { CloseIcon, PlayStoreColorized } from "modules/common/icons";
import { useTranslation } from "react-i18next";
import AppleIcon from "@mui/icons-material/Apple";
import theme from "utils/theme";
import { TDialogItems } from "../Types";

export default function MobileDialog({ onClose }: TDialogItems) {
  const { t } = useTranslation();

  const DownloadButtons = [
    {
      title: "Download app",
      icon: <PlayStoreColorized />,
      disabled: false,
      link: APP_LINK_GOOGLE_PLAY,
      key: "android",
    },
    {
      title: "Download app",
      icon: <AppleIcon />,
      disabled: false,
      link: APP_LINK_APPLE_STORE,
      key: "iOS",
    },
  ];
  return (
    <Grid container display={{ xs: "flex", sm: "none" }} flexDirection="column">
      <Grid
        container
        item
        xs={12}
        sx={{ borderBottom: "1px solid grey" }}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item order={0} display="flex" alignSelf="flex-end">
          <IconButton onClick={onClose} sx={{ padding: 2 }}>
            <CloseIcon activeIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <Image src={BlueLogo} style={{ width: "25vw" }} />
        </Grid>
        <Grid item textAlign="center" mt={2} mb={4}>
          <Typography variant="h5" color="common.white">
            {t("base.title")}
          </Typography>
          <Typography variant="body1">
            {t("about.funPlayToEarnQuizApp")}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        justifyContent="center"
        order={2}
        xs={12}
        rowSpacing={3}
        py={2}
      >
        {DownloadButtons.map((button) => (
          <Grid
            item
            xs={8}
            display="flex"
            flexDirection="column"
            alignItems="center"
            key={button.key}
          >
            <Button
              fullWidth
              type="button"
              href={button.link}
              target="_blank"
              variant="contained"
              sx={{
                opacity: 1,
                textTransform: "none",
                backgroundColor: "common.black",
                borderRadius: "25px",
                border: `1px solid #BBBBBC`,
                color: theme.palette.info.main,
                py: 1,
              }}
              startIcon={button.icon}
            >
              {button.title}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
