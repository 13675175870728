import { useCallback, useState } from "react";
import {
  Box,
  Drawer,
  DrawerProps,
  Grid,
  IconButton,
  keyframes,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { Logo } from "modules/common/icons";
import theme from "utils/theme";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIco from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { sections } from "modules/common/components/TopBar/TopBar";
import CustomLink from "modules/common/components/CustomLink/CustomLink";

type TAppDrawer = DrawerProps & {
  onClosedClicked: () => void;
  onLinkClicked: () => void;
  passedLink: string;
};

export default function AppDrawer({
  onClosedClicked,
  onLinkClicked,
  passedLink,
  ...props
}: TAppDrawer) {
  const [currentLink, setCurrentLink] = useState<string>(passedLink);

  const handleLinkClick = useCallback(
    (ref: string) => {
      setCurrentLink(ref);
      onLinkClicked();
    },
    [onLinkClicked],
  );

  const slideFromRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
 `;

  return (
    <Drawer
      anchor="right"
      PaperProps={{
        sx: {
          width: { xs: "100%", sm: "50%" },
          bgcolor: theme.palette.background.default,
        },
      }}
      sx={{ display: { lg: "none" }, transition: "ease-in" }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Grid
        container
        justifyContent="space-between"
        maxHeight="50px"
        px={2}
        mt={1}
      >
        <Grid item>
          <Logo
            sx={{
              alignSelf: "center",
              fontSize: { xs: "35vw", sm: "20vw", mg: "20vw" },
              maxHeight: "50px",
            }}
          />
        </Grid>
        <Grid item>
          <IconButton onClick={onClosedClicked}>
            <CloseIcon
              sx={{
                color: "grey.400",
                fontSize: { xs: "8vw", sm: "6vw", md: "3vw" },
              }}
            />
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        container
        height="100vh"
        flexDirection="column"
        alignItems="stretch"
        justifyContent="center"
      >
        <Grid item>
          <List>
            {sections.map((section, index) => (
              <ListItem key={section.to} disablePadding>
                <Box
                  sx={{
                    width: "100%",
                    bgcolor:
                      currentLink === section.to
                        ? theme.palette.background.paper
                        : "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    marginY: 1,
                    paddingY: 1,
                    animation: `${slideFromRight} ${
                      index / 10
                    }s ease-in running`,
                  }}
                >
                  {currentLink === section.to && (
                    <ChevronRightIcon
                      sx={{
                        fontSize: { xs: "10vw", sm: "5vw", md: "5vw" },
                        color: theme.palette.success.main,
                        stroke: theme.palette.success.main,
                        strokeWidth: 2,
                      }}
                    />
                  )}
                  <CustomLink
                    to={section.to}
                    onClick={() => handleLinkClick(section.to)}
                    onSetActive={() => setCurrentLink(section.to)}
                  >
                    <Typography variant="h2">{section.title}</Typography>
                  </CustomLink>
                  {currentLink === section.to && (
                    <ChevronLeftIco
                      sx={{
                        fontSize: { xs: "10vw", sm: "5vw", md: "5vw" },
                        color: theme.palette.success.main,
                        stroke: theme.palette.success.main,
                        strokeWidth: 2,
                      }}
                    />
                  )}
                </Box>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Drawer>
  );
}
