/* eslint-disable react/jsx-props-no-spreading */
import { SvgIcon, SvgIconProps } from "@mui/material";

function CloseIcon({
  activeIcon,
  sx,
  ...props
}: { activeIcon?: boolean } & SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 29.25 29.25"
      {...props}
      sx={{
        ...sx,
        "&:hover": {
          transform: `rotate(${360}deg)`,
          transition: `transform ${300}ms`,
        },
      }}
    >
      <path
        id="Icon_ionic-ios-close-circle"
        data-name="Icon ionic-ios-close-circle"
        d="M18,3.375A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Zm3.705,19.92L18,19.589l-3.705,3.705a1.124,1.124,0,1,1-1.589-1.589L16.411,18l-3.705-3.705a1.124,1.124,0,0,1,1.589-1.589L18,16.411l3.705-3.705a1.124,1.124,0,1,1,1.589,1.589L19.589,18l3.705,3.705a1.129,1.129,0,0,1,0,1.589A1.116,1.116,0,0,1,21.705,23.295Z"
        transform="translate(-3.375 -3.375)"
        fill="#fff"
        opacity={activeIcon ? "1" : "0.5"}
      />
    </SvgIcon>
  );
}
CloseIcon.defaultProps = {
  activeIcon: false,
};
export default CloseIcon;
