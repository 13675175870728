import { SvgIcon } from "@mui/material";
import { TSocialIcons } from "../types";

export default function TwitterFooter({ hover, ...props }: TSocialIcons) {
  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <rect
        id="fb_style_"
        data-name="fb (style)"
        width="32"
        height="32"
        rx="16"
        fill={hover ? "#e6e8ec" : "#141416"}
      />
      <path
        id="Icon_ionic-logo-twitter"
        data-name="Icon ionic-logo-twitter"
        d="M16.672,5.97a6.329,6.329,0,0,1-1.8.5,3.143,3.143,0,0,0,1.377-1.733,6.22,6.22,0,0,1-1.989.759,3.129,3.129,0,0,0-5.416,2.14,3.067,3.067,0,0,0,.081.714A8.871,8.871,0,0,1,2.474,5.072a3.137,3.137,0,0,0,.973,4.182,3.058,3.058,0,0,1-1.423-.39V8.9a3.133,3.133,0,0,0,2.513,3.071,3.151,3.151,0,0,1-.825.112,2.956,2.956,0,0,1-.589-.06A3.136,3.136,0,0,0,6.05,14.2a6.276,6.276,0,0,1-3.89,1.338,6.346,6.346,0,0,1-.747-.042A8.75,8.75,0,0,0,6.2,16.915,8.854,8.854,0,0,0,15.12,8c0-.136,0-.272-.009-.4a6.372,6.372,0,0,0,1.561-1.623Z"
        transform="translate(7.556 5.944)"
        fill={hover ? "#141416" : "#e6e8ec"}
      />
    </SvgIcon>
  );
}
