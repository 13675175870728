/* eslint-disable react/jsx-props-no-spreading */
import { Grid, GridProps, Stack, Typography } from "@mui/material";
import { aboutGrid, about_illustration } from "assets";
import { Image, MotionWrap, SectionHeader } from "modules/common/components";
import { useTranslation } from "react-i18next";
import { aboutText } from "./constants";

function CoinImage({ ...props }: GridProps) {
  return (
    <Grid
      container
      item
      {...props}
      lg={4}
      sm={6}
      xs={12}
      sx={{
        background: `transparent url(${aboutGrid}) no-repeat center`,
        backgroundSize: { xs: "90%", sm: "80%", md: "55%", lg: "90%" },
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12} sm={9} md={6} lg={10}>
        <Image
          src={about_illustration}
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
          alt="about_illustration"
        />
      </Grid>
    </Grid>
  );
}

function About() {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="center" mb={3}>
      <Grid
        container
        item
        xs={11.5}
        md={9}
        sx={{
          background: "rgba(0,0,0,0.4)",
          borderRadius: "30px",
        }}
        justifyContent="space-between"
        p={4}
      >
        <Grid container item xs={12} sm={6}>
          <Grid item>
            <SectionHeader
              title={aboutText.title}
              subtitle={aboutText.subtitle}
              content={aboutText.content}
            />
          </Grid>
          <Grid item>
            <CoinImage display={{ xs: "flex", sm: "none" }} mt={5} />
          </Grid>
          <Grid item>
            <Stack width={{ xs: "100%", md: "35vw" }}>
              <Typography variant="subtitle2" mt={2}>
                {t("about.aboutInfoSection1")}
              </Typography>
              <Typography variant="subtitle2" mt={2}>
                {t("about.aboutInfoSection2")}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <CoinImage display={{ xs: "none", sm: "flex" }} />
      </Grid>
    </Grid>
  );
}

export default MotionWrap(About);
