import { Grid } from "@mui/material";
import { PrivacyAndCookies, SocialLinksButtons } from "./components";

type TSocialLinks = {
  showTextLinks: boolean;
};

export default function SocialLinks({ showTextLinks }: TSocialLinks) {
  return (
    <Grid container rowGap={{ xs: 1, md: 2 }} justifyContent="center">
      <SocialLinksButtons />
      {showTextLinks ? <PrivacyAndCookies /> : null}
    </Grid>
  );
}
