import { Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Footer, TopBar } from "..";

export default function Layout() {
  return (
    <Stack justifyContent="space-between">
      <TopBar />
      <Outlet />
      <Footer showTextLinks />
    </Stack>
  );
}
