/* eslint-disable react/jsx-props-no-spreading */
import { SvgIcon, SvgIconProps } from "@mui/material";

export default function StarLine(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 12.937 140.766" {...props}>
      <g
        id="Group_25478"
        data-name="Group 25478"
        transform="translate(-290 -270.956)"
      >
        <path
          id="Path_44016"
          data-name="Path 44016"
          d="M249.5,357.233V276.977"
          transform="translate(46.5 -5.67)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="0.7"
        />
        <g id="Group_25471" data-name="Group 25471" transform="translate(0 -9)">
          <g
            id="Group_25477"
            data-name="Group 25477"
            transform="translate(-150.5 -4059.352)"
          >
            <path
              id="Path_53163"
              data-name="Path 53163"
              d="M304.988,578.141a9.024,9.024,0,0,0,4.608-2.054,8.64,8.64,0,0,0,1.869-4.61s.25,3.1,1.865,4.77,4.595,1.893,4.595,1.893-2.98.209-4.595,1.783a8.212,8.212,0,0,0-1.865,4.511,6.76,6.76,0,0,0-1.679-4.2C308.166,578.664,304.988,578.141,304.988,578.141Z"
              transform="translate(135.512 3895.641)"
              fill="#fff"
            />
            <path
              id="Path_53164"
              data-name="Path 53164"
              d="M304.988,578.141a9.024,9.024,0,0,0,4.608-2.054,8.64,8.64,0,0,0,1.869-4.61s.25,3.1,1.865,4.77,4.595,1.893,4.595,1.893-2.98.209-4.595,1.783a8.212,8.212,0,0,0-1.865,4.511,6.76,6.76,0,0,0-1.679-4.2C308.166,578.664,304.988,578.141,304.988,578.141Z"
              transform="translate(135.512 3878.641)"
              fill="#fff"
            />
            <path
              id="Path_53165"
              data-name="Path 53165"
              d="M304.988,578.141a9.024,9.024,0,0,0,4.608-2.054,8.64,8.64,0,0,0,1.869-4.61s.25,3.1,1.865,4.77,4.595,1.893,4.595,1.893-2.98.209-4.595,1.783a8.212,8.212,0,0,0-1.865,4.511,6.76,6.76,0,0,0-1.679-4.2C308.166,578.664,304.988,578.141,304.988,578.141Z"
              transform="translate(135.512 3861.641)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
