import { Props } from "modules/common/components/SectionHeader/SectionHeader";
import { Trans } from "react-i18next";

export const discoverText: Props = {
  title: {
    text: "",
  },
  subtitle: {
    text: <Trans i18nKey="features.discoverNewCrypto" />,
    variant: "h2",
    textAlign: "center",
  },

  content: {
    text: <Trans i18nKey="features.educateTheCrypto" />,
    variant: "subtitle2",
    textAlign: "center",
  },
};
