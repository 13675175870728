import { AromaCoin, CasperCoin, ShimmerCoin } from "modules/common/icons";
import { Trans } from "react-i18next";

export const coins = [
  {
    reward: <Trans i18nKey="features.shimmerRewards" />,
    image: <ShimmerCoin sx={{ fontSize: { xs: "6rem", xl: "8rem" } }} />,
    name: "Shimmer",
    link: "https://shimmer.network/",
  },
  {
    reward: <Trans i18nKey="features.casperRewards" />,
    image: <CasperCoin sx={{ fontSize: { xs: "6rem", xl: "8rem" } }} />,
    name: "Casper",
    link: "https://casper.network/",
  },
  {
    reward: <Trans i18nKey="features.cryptoChefsRewards" />,
    image: <AromaCoin sx={{ fontSize: { xs: "6rem", xl: "8rem" } }} />,
    name: "CryptoChefs",
    link: "https://cryptochefs.io/",
  },
];
