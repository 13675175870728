import CircularProgress from "@mui/material/CircularProgress";
import { Stack } from "@mui/material";

export default function LoadingSpinner() {
  return (
    <Stack height="100vh" alignItems="center" justifyContent="center">
      <CircularProgress disableShrink thickness={5} />
    </Stack>
  );
}
