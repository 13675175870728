/* eslint-disable react/no-unescaped-entities */

import { Box, Grid, Link, Typography } from "@mui/material";
import { BlueLogo } from "assets";
import { Image, OtherContainer } from "modules/common/components";
import { Trans, useTranslation } from "react-i18next";
import theme from "utils/theme";

const termsList = [
  {
    title: "terms.theApplication.title",
    content: "terms.theApplication.content",
    description: "terms.theApplication.description",
    secondContent: "terms.theApplication.secondContent",
  },
  {
    title: "terms.quizRewards.title",
    content: "terms.quizRewards.content",
    description: "terms.quizRewards.description",
    secondContent: "",
  },
  {
    title: "terms.limitation.title",
    content: "terms.limitation.content",
    description: "",
    secondContent: "",
  },
  {
    title: "terms.applicationProvided.title",
    content: "terms.applicationProvided.content",
    description: "",
    secondContent: "",
  },
  {
    title: "terms.downTime.title",
    content: "terms.downTime.content",
    description: "",
    secondContent: "",
  },
  {
    title: "terms.tradeMark.title",
    content: "terms.tradeMark.content",
    description: "",
    secondContent: "",
  },

  {
    title: "terms.intellectual.title",
    content: "terms.intellectual.content",
    description: "",
    secondContent: "",
  },
  {
    title: "terms.prohibitedUses.title",
    content: "terms.prohibitedUses.content",
    description: "",
    secondContent: "",
  },
  {
    title: "terms.thirdParty.title",
    content: "terms.thirdParty.content",
    description: "",
    secondContent: "",
  },
  {
    title: "terms.liability.title",
    content: "terms.liability.content",
    description: "",
    secondContent: "",
  },
  {
    title: "terms.indemnification.title",
    content: "terms.indemnification.content",
    description: "",
    secondContent: "",
  },
  {
    title: "terms.shutdown.title",
    content: "terms.shutdown.content",
    description: "",
    secondContent: "",
  },
  {
    title: "terms.personalAndProtection.title",
    content: "terms.personalAndProtection.content",
    description: "",
    secondContent: "",
  },
  {
    title: "terms.severability.title",
    content: "terms.severability.content",
    description: "",
    secondContent: "",
  },
  {
    title: "terms.noWaiver.title",
    content: "terms.noWaiver.content",
    description: "",
    secondContent: "",
  },
  {
    title: "terms.assignment.title",
    content: "terms.assignment.content",
    description: "",
    secondContent: "",
  },
  {
    title: "terms.governing.title",
    content: "terms.governing.content",
    description: "",
    secondContent: "",
  },
];

const definitions = [
  {
    title: "terms.definitions.application",
  },
  {
    title: "terms.definitions.company",
  },
  {
    title: "terms.definitions.intellectual",
  },
  {
    title: "terms.definitions.partners",
  },
  {
    title: "terms.definitions.terms",
  },
  {
    title: "terms.definitions.user",
  },
];

function TransComponent({
  text,
  description,
}: {
  text: string;
  description: boolean;
}) {
  return (
    <Typography
      variant="body1"
      fontWeight="400"
      color={description ? "common.black" : theme.palette.text.secondary}
    >
      <Trans
        i18nKey={text}
        components={{
          br: <br />,
          bold: <strong />,
          uppercase: <i />,
          typography: (
            <Typography fontWeight="400" sx={{ minWidth: "fit-content" }} />
          ),
          ttypogrphy: (
            <Typography
              fontWeight="400"
              sx={{ minWidth: "fit-content", ml: 4 }}
            />
          ),
          a: (
            <Link
              target="_blank"
              href="https://cryptonaire.app/privacy-policy"
              sx={{ cursor: "pointer" }}
            />
          ),
        }}
      />
    </Typography>
  );
}

function TermsOfUse() {
  const { t } = useTranslation();

  return (
    <OtherContainer
      backDest="/privacy-policy"
      backTitle="Go Back"
      forwardDest="/"
      forwardTitle="Cryptonaire"
    >
      <Grid
        container
        py={8}
        px={{ xs: 1, md: 15 }}
        flexDirection="column"
        rowGap={5}
      >
        <Grid item xs={3} md={1} alignSelf="center">
          <Image src={BlueLogo} style={{ width: "100%" }} />
        </Grid>
        <Grid item textAlign="center">
          <Typography variant="h5" textTransform="uppercase" mb={1}>
            {t("terms.header.title")}
          </Typography>
          <Typography variant="h3" textTransform="uppercase">
            {t("terms.header.subtitle")}
          </Typography>
          <Typography variant="h6" textTransform="uppercase">
            {t("terms.header.hint")}
          </Typography>
          <Typography variant="h4">{t("terms.header.versionDate")}</Typography>
        </Grid>
        <Grid container item>
          <Grid item mb={1}>
            <Typography
              variant="h5"
              fontSize={{ xs: "1rem", md: "1.5rem" }}
            >{`${1}. Definitions`}</Typography>
          </Grid>
          <Grid container item>
            {definitions.map((item) => (
              <Grid item xs={12} ml={5} key={`${item.title}`} mt={2}>
                <TransComponent text={item.title} description={false} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item rowGap={2}>
          {termsList.map((item, i) => (
            <>
              <Grid item key={item.title} mt={2}>
                <Typography
                  variant="h5"
                  fontSize={{ xs: "1rem", md: "1.5rem" }}
                  mb={1}
                >{`${i + 2}. ${t(item.title)}`}</Typography>
              </Grid>
              <Grid item ml={5}>
                <Typography variant="body1" fontWeight="400">
                  <TransComponent text={item.content} description={false} />
                </Typography>

                {item.description.length > 0 ? (
                  <Box
                    bgcolor={theme.palette.success.dark}
                    borderRadius="16px"
                    py={2}
                    px={5}
                    mt={3}
                    textAlign="center"
                  >
                    <TransComponent text={item.description} description />
                  </Box>
                ) : null}
                {item.secondContent.length > 0 ? (
                  <Grid item mt={1}>
                    <TransComponent
                      text={item.secondContent}
                      description={false}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </>
          ))}
        </Grid>
      </Grid>
    </OtherContainer>
  );
}

export default TermsOfUse;
