/* eslint-disable react/jsx-props-no-spreading */
import { SvgIcon, SvgIconProps } from "@mui/material";

export default function ScanMe(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 18.776 18.766" {...props}>
      <path
        id="qrcode-viewfinder-svgrepo-com"
        d="M7.313,13a.745.745,0,0,0,.818-.839V9.748a1.434,1.434,0,0,1,1.6-1.606H12.2a.749.749,0,0,0,.828-.828A.746.746,0,0,0,12.2,6.5H9.7c-2.127,0-3.211,1.053-3.211,3.16v2.505A.752.752,0,0,0,7.313,13Zm17.119,0a.748.748,0,0,0,.828-.839V9.656c0-2.107-1.064-3.16-3.211-3.16h-2.5a.749.749,0,0,0-.839.818.752.752,0,0,0,.839.828h2.475a1.435,1.435,0,0,1,1.585,1.606v2.413A.749.749,0,0,0,24.432,13Zm-9,1.994V11.425a.447.447,0,0,0-.45-.45H11.424a.447.447,0,0,0-.45.45v3.569a.439.439,0,0,0,.45.44h3.559A.439.439,0,0,0,15.432,14.994ZM17.2,11.865h2.679v2.679H17.2Zm1.9,1.9V12.652H17.989v1.115Zm-5.338,0V12.652H12.641v1.115Zm-1.9,3.446h2.679v2.679H11.863Zm8.774.348V16.446H19.523v1.115Zm-3.078,0V16.446H16.445v1.115Zm-3.794,1.544V17.991H12.641v1.115Zm5.338,0V17.991H17.979v1.115Zm.45,6.156h2.5c2.148,0,3.211-1.064,3.211-3.17V19.6a.823.823,0,1,0-1.647,0V22.01a1.435,1.435,0,0,1-1.585,1.606H19.554a.823.823,0,1,0,0,1.646Zm-9.858,0H12.2a.823.823,0,1,0,0-1.646H9.726a1.434,1.434,0,0,1-1.6-1.606V19.6a.749.749,0,0,0-.818-.839.748.748,0,0,0-.828.839v2.5C6.484,24.208,7.568,25.262,9.7,25.262ZM17.56,20.65V19.535H16.445V20.65Zm3.078,0V19.535H19.523V20.65Zm.133-5.655V11.425a.447.447,0,0,0-.45-.45H16.762a.447.447,0,0,0-.45.45v3.569a.439.439,0,0,0,.45.44h3.559A.439.439,0,0,0,20.771,14.994Zm-8.907-3.129h2.679v2.679H11.863Zm3.569,8.468V16.764a.439.439,0,0,0-.45-.44H11.424a.439.439,0,0,0-.45.44v3.569a.447.447,0,0,0,.45.45h3.559A.447.447,0,0,0,15.432,20.333Z"
        transform="translate(-6.484 -6.496)"
        fill="#fff"
      />
    </SvgIcon>
  );
}
