import { ReactElement, useState } from "react";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { popupLogo } from "assets";
import theme from "utils/theme";
import { Image } from "modules/common/components";
import {
  AppleSmallBlack,
  AppleSmallWhite,
  CloseIcon,
  PlayStoreSmall,
  PlayStoreWhite,
} from "modules/common/icons";
import { useTranslation } from "react-i18next";
import { TDialogItems } from "../Types";
import { QrCode } from "./components";

type TStoresButton = {
  title: string;
  activeIcon: ReactElement;
  disableIcon: ReactElement;
  type: "android" | "ios";
};

const StoresButton: TStoresButton[] = [
  {
    title: "Android",
    activeIcon: <PlayStoreSmall sx={{ fontSize: "1rem" }} />,
    disableIcon: <PlayStoreWhite sx={{ fontSize: "1rem" }} />,
    type: "android",
  },
  {
    title: "iOS",
    activeIcon: <AppleSmallBlack sx={{ fontSize: "1rem" }} />,
    disableIcon: <AppleSmallWhite sx={{ fontSize: "1rem" }} />,
    type: "ios",
  },
];

export default function WebDialog({ onClose }: TDialogItems) {
  const { t } = useTranslation();
  const [hover, setHover] = useState<boolean>(false);
  const [buttonType, setButtonType] = useState<"android" | "ios">("android");

  return (
    <Grid container display={{ xs: "none", sm: "flex" }}>
      <Grid
        container
        item
        xs={7}
        sx={{ borderRight: "1px solid grey" }}
        alignItems="center"
        justifyContent="center"
        columnGap={3}
        pl={2}
      >
        <Grid item xs={2.5}>
          <Image src={popupLogo} style={{ width: "100%" }} />
        </Grid>
        <Grid container item xs={8}>
          <Grid item xs={12}>
            <Typography variant="h3" color="common.white">
              {t("base.title")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {t("about.funPlayToEarnQuizApp")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={5} flexDirection="column">
        <Grid item order={1} textAlign="right">
          <IconButton
            onClick={onClose}
            sx={{ cursor: "pointer", zIndex: 99999 }}
            onMouseEnter={() => {
              setHover(true);
            }}
            onMouseLeave={() => {
              setHover(false);
            }}
          >
            <CloseIcon activeIcon={hover} />
          </IconButton>
        </Grid>
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          order={2}
          rowGap={2}
          p={2}
          pb={4}
        >
          <Grid item>
            <QrCode buttonType={buttonType} />
          </Grid>
          <Grid
            container
            item
            xs={10}
            bgcolor={theme.palette.primary.light}
            borderRadius={16}
          >
            {StoresButton.map((store) => (
              <Grid item xs={6} key={store.type}>
                <Button
                  fullWidth
                  variant="text"
                  sx={{
                    backgroundColor:
                      buttonType === store.type
                        ? theme.palette.success.dark
                        : "transparent",
                    boxShadow: 0,
                    alignItems: "center",
                    "&:hover": {
                      backgroundColor:
                        buttonType === store.type
                          ? theme.palette.success.dark
                          : "transparent",
                    },
                  }}
                  onClick={() => setButtonType(store.type)}
                >
                  {buttonType === store.type
                    ? store.activeIcon
                    : store.disableIcon}
                  <Typography
                    variant="body2"
                    ml={0.5}
                    color={
                      buttonType === store.type
                        ? "common.black"
                        : "common.white"
                    }
                    sx={{
                      cursor: "inherit",
                    }}
                  >
                    {store.title}
                  </Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
