import { ArrowLeftTwoTone, ArrowRightTwoTone } from "@mui/icons-material";
import { Fab, Grid } from "@mui/material";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import theme from "utils/theme";
import Footer from "../Footer/Footer";

type TOtherContainer = {
  children: ReactElement;
  backDest: string;
  backTitle: string;
  forwardDest: string;
  forwardTitle: string;
};

function OtherContainer({
  children,
  backDest,
  backTitle,
  forwardDest,
  forwardTitle,
}: TOtherContainer) {
  const navigate = useNavigate();
  return (
    <Grid container>
      <Grid item xs={12}>
        {children}
      </Grid>
      <Grid item xs={12}>
        <Footer showTextLinks={false} />
      </Grid>
      <Fab
        variant="extended"
        size="medium"
        color="primary"
        onClick={() => navigate(backDest)}
        sx={{
          display: { xs: "none", md: "flex" },
          maxWidth: "fit-content",
          margin: 0,
          top: "auto",
          right: "auto",
          bottom: 20,
          left: 20,
          position: "fixed",
          backgroundColor: theme.palette.info.dark,
          cursor: "pointer",
          color: "common.white",
          "&:hover": {
            backgroundColor: "common.white",
            color: theme.palette.info.dark,
          },
        }}
      >
        <ArrowLeftTwoTone />
        {backTitle}
      </Fab>
      <Fab
        variant="extended"
        size="medium"
        color="primary"
        onClick={() => navigate(forwardDest)}
        sx={{
          display: { xs: "none", md: "flex" },
          maxWidth: "fit-content",
          margin: 0,
          top: "auto",
          right: 20,
          bottom: 20,
          left: "auto",
          position: "fixed",
          backgroundColor: theme.palette.info.dark,
          cursor: "pointer",
          color: "common.white",
          "&:hover": {
            backgroundColor: "common.white",
            color: theme.palette.info.dark,
          },
        }}
      >
        {forwardTitle}
        <ArrowRightTwoTone />
      </Fab>
    </Grid>
  );
}

export default OtherContainer;
