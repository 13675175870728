import { Grid } from "@mui/material";
import { MotionWrap } from "modules/common/components";
import { useState } from "react";
import { AppDemo, DownloadDialog, FabDownload, Knowledge } from "./components";

function MainSection() {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Grid
        container
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Knowledge />
        <AppDemo
          onClick={() => {
            setOpen(true);
          }}
        />
        <FabDownload
          onClick={() => {
            setOpen(true);
          }}
        />
      </Grid>
      <DownloadDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
}

export default MotionWrap(MainSection);
