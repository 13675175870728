/* eslint-disable react/jsx-props-no-spreading */
import { SvgIcon, SvgIconProps } from "@mui/material";

export default function QuizIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 23 23" {...props}>
      <g id="Icon" transform="translate(0.249 0.307)">
        <rect
          id="Icon-2"
          data-name="Icon"
          width="23"
          height="23"
          transform="translate(-0.249 -0.307)"
          fill="none"
        />
        <path
          id="Vector"
          d="M9.752.328,17.6,8.18A1.124,1.124,0,0,1,16.016,9.77l-.329-.329v7.386a1.122,1.122,0,0,1-1.122,1.122H12.321A1.122,1.122,0,0,1,11.2,16.827V14.583a1.122,1.122,0,0,0-1.122-1.122H7.834a1.122,1.122,0,0,0-1.122,1.122v2.244a1.122,1.122,0,0,1-1.122,1.122H3.347a1.122,1.122,0,0,1-1.122-1.122V9.442L1.9,9.771A1.124,1.124,0,0,1,.307,8.181L8.159.329a1.122,1.122,0,0,1,1.589,0Z"
          transform="translate(2.269 2.243)"
          fill="#777e90"
        />
      </g>
    </SvgIcon>
  );
}
