import { Fab, Typography } from "@mui/material";
import { BlueLogo } from "assets";
import { Image } from "modules/common/components";
import { useTranslation } from "react-i18next";

export default function FabDownload({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation();
  return (
    <Fab
      variant="extended"
      size="medium"
      color="primary"
      onClick={onClick}
      sx={{
        display: { xs: "none", md: "flex" },
        minWidth: "250px",
        height: "fit-content",
        justifyContent: "flex-start",
        margin: 0,
        top: "auto",
        right: 0,
        bottom: 0,
        left: "auto",
        position: "fixed",
        borderRadius: "0px",
        p: 1,
        px: 2,
        backgroundColor: "rgba(33, 38, 46, 0.8)",
        overflow: "none",
        backdropFilter: "blur(43px)",
        boxShadow: "10px 10px 10px rgba(33, 38, 46, 0.5)",
        cursor: "pointer",
        color: "common.white",
        "&:hover": { backgroundColor: "rgba(33, 38, 46, 1)" },
        borderTop: `0.5px solid rgba(188, 190, 192, 0.3)`,
        borderLeft: `0.5px solid rgba(188, 190, 192, 0.3)`,
      }}
    >
      <Image src={BlueLogo} width="50px" height="50px" alt="blue logo" />
      <Typography
        variant="button"
        textTransform="none"
        sx={{ cursor: "inherit" }}
        ml={2}
        color="common.white"
      >
        {t("about.getTheApp")}
      </Typography>
    </Fab>
  );
}
