import { Grid, Typography } from "@mui/material";
import { Knowledge as KnowledgeWord, Star } from "modules/common/icons";
import { useTranslation } from "react-i18next";

export default function Knowledge() {
  const { t } = useTranslation();
  return (
    <Grid container item xs={12} md={8} xl={12}>
      <Grid container item xs={12} justifyContent="center" alignItems="center">
        <Grid item textAlign="right" mr={2}>
          <Typography variant="h1">{t("about.testYourCrypto")}</Typography>
        </Grid>
        <Grid item textAlign="center">
          <KnowledgeWord
            sx={{
              fontSize: { xs: "60vw", sm: "40vw", md: "25vw", xl: "20vw" },
              height: "auto",
            }}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} alignItems="center" justifyContent="center">
        <Grid item>
          <Typography variant="h1">and Earn</Typography>
        </Grid>
        <Grid item>
          <Star
            sx={{
              mx: 1.5,
              mt: 1,
              fontSize: { xs: "10vw", md: "5vw", xl: "3vw" },
              fontWeight: "800",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
