import { Box, Divider, Typography } from "@mui/material";
import theme from "utils/theme";
import CustomLink from "../../../CustomLink/CustomLink";

type TTopBarLink = {
  active: boolean;
  title: any;
  onClick: () => void;
  onSetActive: () => void;
  to: string;
};

export default function TopBarLink({
  active,
  title,
  onClick,
  onSetActive,
  to,
}: TTopBarLink) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <CustomLink to={to} onClick={onClick} onSetActive={onSetActive}>
        <Typography
          variant="body1"
          color={
            active ? theme.palette.primary.main : theme.palette.text.secondary
          }
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          {title}
        </Typography>
      </CustomLink>
      {active && (
        <Divider
          sx={{ width: "20px", height: "2px", bgcolor: "common.white" }}
        />
      )}
    </Box>
  );
}
