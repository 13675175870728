import { Grid } from "@mui/material";
import { LoadingSpinner } from "modules/common/components";
import { lazy, Suspense } from "react";
import { Cookies } from "./components";

const MainSection = lazy(() => import("./components/MainSection/MainSection"));
const About = lazy(() => import("./components/About/About"));
const Features = lazy(() => import("./components/Features/Features"));
const Supporters = lazy(() => import("./components/Supporters/Supporters"));
const Contact = lazy(() => import("./components/Contact/Contact"));
export default function Home() {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Grid container flexDirection="column" spacing={10} pt={13}>
        <Grid item id="#about" xs={12}>
          <MainSection />
        </Grid>
        <Grid item xs={12}>
          <About />
        </Grid>
        <Grid item id="#features" xs={12}>
          <Features />
        </Grid>
        <Grid item id="#supporters" xs={12}>
          <Supporters />
        </Grid>
        <Grid item id="#contact" xs={12}>
          <Contact />
        </Grid>
      </Grid>
      <Cookies />
    </Suspense>
  );
}
