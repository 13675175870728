import { SvgIcon } from "@mui/material";
import { TSocialIcons } from "../types";

export default function TikTokFooter({ hover, ...props }: TSocialIcons) {
  return (
    /* eslint-disable react/jsx-props-no-spreading */

    <SvgIcon viewBox="0 0 32 32" {...props}>
      <rect
        id="fb_style_"
        data-name="fb (style)"
        width="32"
        height="32"
        rx="16"
        fill={hover ? "#e6e8ec" : "#141416"}
      />
      <path
        id="brand-tiktok-sq-svgrepo-com"
        d="M14.228,2H2.643A.643.643,0,0,0,2,2.643V14.228a.643.643,0,0,0,.643.643H14.228a.643.643,0,0,0,.643-.643V2.643A.643.643,0,0,0,14.228,2ZM12.117,7.615h0a1.865,1.865,0,0,1-.211.008A2.3,2.3,0,0,1,9.978,6.585V10.12a2.614,2.614,0,1,1-2.61-2.609c.055,0,.108,0,.161.008V8.8a1.33,1.33,0,1,0-.161,2.651,1.366,1.366,0,0,0,1.391-1.316l.013-6.006H10a2.294,2.294,0,0,0,2.114,2.048Z"
        transform="translate(7.564 7.564)"
        fill={hover ? "#141416" : "#e6e8ec"}
      />
    </SvgIcon>
  );
}
