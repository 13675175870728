/* eslint-disable react/require-default-props */
import { Grid } from "@mui/material";
import { MotionWrap } from "modules/common/components";
import { CoinsCarousel, EarningRewards, Enroll } from "./Components";

function Features() {
  return (
    <Grid container rowGap={10} sx={{ overflow: "hidden" }}>
      <Grid item xs={12}>
        <EarningRewards />
      </Grid>
      <Grid item xs={12}>
        <CoinsCarousel />
      </Grid>
      <Grid item xs={12}>
        <Enroll />
      </Grid>
    </Grid>
  );
}

export default MotionWrap(Features);
