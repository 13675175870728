import { Collapse, Typography } from "@mui/material";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import theme from "utils/theme";

export default function Description({
  comingSoon,
  hovered,
  description,
}: {
  comingSoon: boolean | undefined;
  hovered: boolean;
  description: null | ReactElement;
}) {
  const { t } = useTranslation();
  if (comingSoon && !hovered) {
    return (
      <Typography
        mt={1}
        variant="body1"
        fontWeight="400"
        textTransform="uppercase"
        color={
          hovered
            ? theme.palette.primary.dark
            : theme.palette.primary.contrastText
        }
      >
        {t("features.comingSoon")}
      </Typography>
    );
  }

  if (description && hovered)
    return (
      <Collapse in>
        <Typography
          variant="body2"
          color={
            hovered
              ? theme.palette.primary.dark
              : theme.palette.primary.contrastText
          }
          fontSize={{ xs: "3vw", sm: "2vw", lg: "1vw" }}
          ml={2}
        >
          {description}
        </Typography>
      </Collapse>
    );
  return null;
}
