/* eslint-disable react/jsx-props-no-spreading */
import { SvgIcon, SvgIconProps } from "@mui/material";

export default function Knowledge(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 408 87" {...props}>
      <g
        id="Group_24807"
        data-name="Group 24807"
        transform="translate(-0.252 0.209)"
      >
        <rect
          id="Rectangle_1298"
          data-name="Rectangle 1298"
          width="408"
          height="87"
          rx="43.5"
          transform="translate(0.252 -0.209)"
          fill="#3393f4"
        />
        <text
          id="KNOWLEDGE"
          transform="translate(203.252 65.791)"
          fill="#fff"
          fontSize="67"
          fontFamily="Rubik-Medium, Rubik"
          fontWeight="500"
        >
          <tspan x="-174" y="0">
            knowledge
          </tspan>
        </text>
      </g>
    </SvgIcon>
  );
}
