/* eslint-disable react/jsx-props-no-spreading */
import { SvgIcon, SvgIconProps } from "@mui/material";

export default function PlayStoreColorized(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 16.191 17.348" {...props}>
      <g id="google-play-store-svgrepo-com" transform="translate(-2 -1)">
        <path
          id="Path_53113"
          data-name="Path 53113"
          d="M11.644,8.807l2.429-2.6L4.647,1.116C4.589,1.058,4.473,1.058,4.3,1Z"
          transform="translate(-0.97)"
          fill="#00f076"
        />
        <path
          id="Path_53114"
          data-name="Path 53114"
          d="M21.018,16.783l3.412-1.85a1.152,1.152,0,0,0,.636-1.041,1.092,1.092,0,0,0-.636-1.041L21.018,11,18.3,13.891Z"
          transform="translate(-6.874 -4.217)"
          fill="#ffc900"
        />
        <path
          id="Path_53115"
          data-name="Path 53115"
          d="M2.231,1.9A.87.87,0,0,0,2,2.536V17.571a1.111,1.111,0,0,0,.231.694l7.633-8.211Z"
          transform="translate(0 -0.38)"
          fill="#00d6ff"
        />
        <path
          id="Path_53116"
          data-name="Path 53116"
          d="M11.644,17.5,4.3,25.307a.822.822,0,0,0,.347-.116L14.073,20.1Z"
          transform="translate(-0.97 -6.959)"
          fill="#ff3a44"
        />
      </g>
    </SvgIcon>
  );
}
