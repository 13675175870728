import Carousel from "nuka-carousel";
import { Container } from "@mui/material";
import CreatorItem from "../CreatorItem";
import { coins } from "../../constants";

export default function CoinsCarouselWeb() {
  return (
    <Container>
      <Carousel
        slidesToShow={3}
        dragging={false}
        disableEdgeSwiping
        renderCenterLeftControls={() => null}
        renderCenterRightControls={() => null}
        renderBottomCenterControls={() => null}
        renderAnnounceSlideMessage={({ currentSlide, count }) =>
          `Slide ${currentSlide + 1} of ${count}`
        }
        cellSpacing={150}
        style={{
          height: "fit-content",
        }}
      >
        {coins.map((coin) => (
          <CreatorItem
            key={coin.name}
            title={coin.name}
            coin={coin.image}
            reward={coin.reward}
            link={coin.link}
          />
        ))}
      </Carousel>
    </Container>
  );
}
