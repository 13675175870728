import { SvgIcon } from "@mui/material";
import { TSocialIcons } from "../types";

export default function DiscordFooter({ hover, ...props }: TSocialIcons) {
  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <rect
        id="tw_style_"
        data-name="tw (style)"
        width="32"
        height="32"
        rx="16"
        fill={hover ? "#e6e8ec" : "#141416"}
      />
      <path
        id="Icon_awesome-discord"
        data-name="Icon awesome-discord"
        d="M8.25,6.747a.725.725,0,1,1-.725-.786.757.757,0,0,1,.725.786ZM4.931,5.967a.792.792,0,0,0,0,1.578.757.757,0,0,0,.725-.786.752.752,0,0,0-.725-.795Zm7.5-4.5V14.211c-1.79-1.582-1.217-1.059-3.3-2.991l.376,1.314H1.457A1.46,1.46,0,0,1,0,11.071V1.464A1.46,1.46,0,0,1,1.457,0h9.522A1.46,1.46,0,0,1,12.435,1.464ZM10.41,8.2A9.515,9.515,0,0,0,9.387,4.057a3.513,3.513,0,0,0-1.995-.746l-.1.112a4.729,4.729,0,0,1,1.769.905,6.038,6.038,0,0,0-5.308-.206c-.263.121-.419.206-.419.206A4.8,4.8,0,0,1,5.2,3.4L5.13,3.312a3.513,3.513,0,0,0-1.994.746A9.515,9.515,0,0,0,2.113,8.2,2.576,2.576,0,0,0,4.281,9.28s.263-.32.476-.59A2.21,2.21,0,0,1,3.51,7.854c.1.073.279.167.291.178a5.179,5.179,0,0,0,4.434.248,4.066,4.066,0,0,0,.817-.42,2.242,2.242,0,0,1-1.286.846c.214.27.469.575.469.575A2.6,2.6,0,0,0,10.409,8.2Z"
        transform="translate(10 9.521)"
        fill={hover ? "#141416" : "#e6e8ec"}
      />
    </SvgIcon>
  );
}
