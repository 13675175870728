import { Grow } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef } from "react";

export const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
    /* eslint-disable react/jsx-props-no-spreading */
  ) => <Grow appear ref={ref} {...props} />,
);
