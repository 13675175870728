/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import { Grid, GridProps, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import theme from "utils/theme";
import { Description } from "./components";

export default function FeaturesSection({
  comingSoon,
  feature,
  icon,
  description,
  hovered,
  ...props
}: GridProps & {
  feature: string | ReactElement;
  description: ReactElement | null;
  hovered: boolean;
  icon?: ReactElement;
  comingSoon?: boolean;
}) {
  return (
    <Grid container item xs={12} {...props}>
      <Grid
        container
        item
        xs={12}
        md={10}
        bgcolor={hovered ? theme.palette.success.dark : theme.palette.info.dark}
        borderRadius="16px"
        mt={2}
        p={2}
        alignItems="center"
      >
        <Grid item xs={1} display="flex" textAlign="center" alignItems="center">
          {icon}
        </Grid>
        <Grid
          container
          item
          xs={11}
          flexDirection={comingSoon ? "row" : "column"}
          alignItems={comingSoon ? "center" : "left"}
        >
          <Grid item mx={2}>
            <Typography
              alignSelf="left"
              variant="subtitle2"
              color={
                hovered
                  ? theme.palette.primary.dark
                  : theme.palette.secondary.contrastText
              }
            >
              {feature}
            </Typography>
          </Grid>
          <Grid item>
            <Description
              hovered={hovered}
              comingSoon={comingSoon}
              description={description}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
