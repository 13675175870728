import { Button, Grid, Typography } from "@mui/material";
import {
  CustomLink,
  MotionWrap,
  SectionHeader,
} from "modules/common/components";
import { useTranslation } from "react-i18next";
import { discoverText } from "./constants";
import { MobileCarousel, WebCarousel } from "./components";

function CoinsCarousel() {
  const { t } = useTranslation();

  return (
    <Grid container mt={5} spacing={8}>
      <Grid container item xs={12} justifyContent="center">
        <Grid
          item
          width={{ xs: "90vw", sm: "50vw", md: "30vw" }}
          textAlign="center"
        >
          <SectionHeader
            title={discoverText.title}
            subtitle={discoverText.subtitle}
            content={discoverText.content}
            alignItems="center"
          />
        </Grid>
      </Grid>

      <Grid item xs={12} display={{ xs: "none", md: "flex" }}>
        <WebCarousel />
      </Grid>
      <Grid item xs={12} display={{ xs: "flex", md: "none" }}>
        <MobileCarousel />
      </Grid>

      <Grid container item xs={12} flexDirection="column" rowGap={3}>
        <Grid item textAlign="center">
          <Typography
            textTransform="none"
            variant="subtitle2"
            fontSize={{ xs: "3vw", md: "1vw" }}
            fontWeight="500"
          >
            {t("features.addYourWeb3")}
          </Typography>
        </Grid>
        <Grid item textAlign="center">
          <Button variant="outlined" size="large">
            <CustomLink to="#contact">
              <Typography
                textTransform="none"
                variant="subtitle2"
                color="inherit"
                sx={{ cursor: "inherit" }}
              >
                {t("features.letsChat")}
              </Typography>
            </CustomLink>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MotionWrap(CoinsCarousel);
