/* eslint-disable no-console */
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
import { ReactElement, useCallback } from "react";
import {
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID,
} from "./constants/constants";
import { FirebaseContext } from "./firebaseContext";

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
};

export function FirebaseProvider({ children }: { children: ReactElement }) {
  let fbApp: any;
  let fbAnalytics: any;

  try {
    fbApp = initializeApp(firebaseConfig);
    fbAnalytics = getAnalytics(fbApp);
  } catch (e) {
    console.debug("error from FB Provider", e);
  }

  const fbAnalyticsLogEvent = useCallback(
    (eventName: string, eventParams: any, options: any) => {
      if (!fbAnalytics) {
        console.debug(`Warning: Unable to log analytics event: ${eventName}`);
        return null;
      }
      return logEvent(fbAnalytics, eventName, eventParams, options);
    },
    [fbAnalytics],
  );

  const fbAnalyticsSetUserId = useCallback(
    (userId: any, options: any) => {
      if (!fbAnalytics) {
        console.debug(`Warning: Unable to set analytics user id: ${userId}`);
        return null;
      }
      return setUserId(fbAnalytics, userId, options);
    },
    [fbAnalytics],
  );

  return (
    <FirebaseContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ fbApp, fbAnalytics, fbAnalyticsLogEvent, fbAnalyticsSetUserId }}
    >
      {children}
    </FirebaseContext.Provider>
  );
}
