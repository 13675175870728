import { Grid, GridProps, Stack } from "@mui/material";
import { featureGroupbg, flashEarn, leaderboard, quiz, spinWin } from "assets";
import { Image, MotionWrap, SectionHeader } from "modules/common/components";
import {
  FlashCards,
  QuizIcon,
  LeaderboardIcon,
  StarIcon,
  BlackQuizIcon,
  BlackFlashCards,
  BlackStarIcon,
  BlackLeaderboardIcon,
} from "modules/common/icons";
import { useState } from "react";
import { Trans } from "react-i18next";
import { FeaturesSection } from "./components";
import { earningRewardsText } from "./constants";

const Features = [
  {
    title: <Trans i18nKey="features.quiz" />,
    icon: <QuizIcon />,
    description: <Trans i18nKey="features.quizSubText" />,
    activeIcon: <BlackQuizIcon />,
    image: quiz,
    comingSoon: false,
    key: "quiz",
  },
  {
    title: <Trans i18nKey="features.leaderboard" />,
    icon: <LeaderboardIcon />,
    description: <Trans i18nKey="features.leaderboardSubText" />,
    activeIcon: <BlackLeaderboardIcon />,
    image: leaderboard,
    comingSoon: false,
    key: "leaderboard",
  },
  {
    title: <Trans i18nKey="features.spinWin" />,
    icon: <StarIcon />,
    description: <Trans i18nKey="features.spinWinSubText" />,
    activeIcon: <BlackStarIcon />,
    image: spinWin,
    comingSoon: false,
    key: "spinWin",
  },
  {
    title: <Trans i18nKey="features.flashLearn" />,
    icon: <FlashCards />,
    description: <Trans i18nKey="features.flashLearnSubText" />,
    activeIcon: <BlackFlashCards />,
    image: flashEarn,
    comingSoon: false,
    key: "flashLearn",
  },
];
function RewardImage({ image, ...props }: { image: string } & GridProps) {
  return (
    <Grid
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      order={{ xs: 1, md: 0 }}
      container
      item
      xs={12}
      md={6}
      pt={{ xs: 7, md: 15 }}
      mb={{ xs: 3, md: 0 }}
      flexDirection={{ xs: "column", lg: "row" }}
      alignItems="center"
      justifyContent="center"
      sx={{
        background: `transparent url(${featureGroupbg}) no-repeat center`,
        backgroundSize: { xs: "105%", sm: "70%", md: "90%", lg: "70%" },
        backgroundPositionX: {
          xs: 5,
          sm: "center",
          md: 35,
          lg: "center",
        },
      }}
    >
      <Grid
        item
        xs={8}
        sm={5}
        md={7}
        lg={5}
        textAlign="center"
        alignItems="self-end"
        ml={{ sm: -3, lg: -3.4 }}
      >
        <Image
          loading="lazy"
          effect="opacity"
          alt="contact"
          height="auto"
          src={image}
          width="95%"
        />
      </Grid>
    </Grid>
  );
}
function EarningRewards() {
  const [hover, setHover] = useState<any>("");
  const [image, setImage] = useState(leaderboard);
  return (
    <Grid container justifyContent="center" position="relative">
      <RewardImage image={image} display={{ xs: "none", md: "flex" }} />
      <Grid
        container
        item
        xs={12}
        md={6}
        justifyContent="center"
        alignItems="flex-end"
      >
        <Grid item xs={10} sm={8} md={8.5} lg={8} order={{ xs: 1, md: 2 }}>
          <Stack width={{ xs: "90vw", sm: "50vw", md: "25vw" }}>
            <SectionHeader
              title={earningRewardsText.title}
              subtitle={earningRewardsText.subtitle}
              content={earningRewardsText.content}
            />
          </Stack>
        </Grid>
        <Grid item>
          <RewardImage image={image} display={{ xs: "flex", md: "none" }} />
        </Grid>
        <Grid order={{ xs: 3 }} container item xs={10} sm={8} md={8.5} lg={8}>
          {Features.map((feature) => (
            <FeaturesSection
              key={`feature:${feature.key}`}
              feature={feature.title}
              description={feature.description}
              icon={hover === feature.title ? feature.activeIcon : feature.icon}
              hovered={hover === feature.title}
              comingSoon={feature.comingSoon}
              onMouseEnter={() => {
                setHover(feature.title);
                setImage(feature.image);
              }}
              onMouseLeave={() => {
                setHover("");
                setImage(leaderboard);
              }}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
export default MotionWrap(EarningRewards);
