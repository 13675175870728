import { Grid, Typography } from "@mui/material";
import { FooterLogo } from "modules/common/icons";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Logo() {
  const { t } = useTranslation();
  return (
    <Grid
      container
      item
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <FooterLogo
        sx={{ fontSize: { xs: "30vw", md: "11vw" }, height: "auto" }}
      />
      <Typography variant="h6">{t("footer.title")}</Typography>
    </Grid>
  );
}
