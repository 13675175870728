import { Container, Grid, IconButton } from "@mui/material";
import { ArrowRightGrey } from "modules/common/icons";
import ArrowLeft from "modules/common/icons/Features/ArrowLeft/ArrowLeft";
import Carousel from "nuka-carousel";
import { coins } from "../../constants";
import CreatorItem from "../CreatorItem";

export default function CoinsCarouselMobile() {
  return (
    <Container
      sx={{ width: "100%", height: "auto", overflow: "hidden" }}
      disableGutters
    >
      <Carousel
        wrapAround
        autoplayInterval={1000}
        swiping
        autoplay={false}
        animation="fade"
        renderCenterLeftControls={({ previousSlide }) => (
          <IconButton onClick={previousSlide}>
            <ArrowLeft sx={{ fontSize: "22px" }} />
          </IconButton>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <IconButton onClick={nextSlide}>
            <ArrowRightGrey sx={{ fontSize: "22px" }} />
          </IconButton>
        )}
        renderBottomCenterControls={() => null}
      >
        {coins.map((coin, index, array) => (
          <Grid container key={`name:${coin.name}`}>
            <Grid container item xs justifyContent="center" alignItems="center">
              {index === 0 ? (
                <CreatorItem
                  sx={{
                    opacity: 0.5,
                    position: "absolute",
                    mr: "-40vw",
                    transform: "scale(0.95)",
                  }}
                  title={array[array.length - 1].name}
                  coin={array[array.length - 1].image}
                  reward={array[array.length - 1].reward}
                  link={coin.link}
                />
              ) : (
                <CreatorItem
                  sx={{
                    opacity: 0.5,
                    position: "absolute",
                    mr: "-40vw",
                    transform: "scale(0.95)",
                  }}
                  title={array[index - 1].name}
                  coin={array[index - 1].image}
                  reward={array[index - 1].reward}
                  link={coin.link}
                />
              )}

              <CreatorItem
                active
                title={coin.name}
                coin={coin.image}
                reward={coin.reward}
                sx={{ zIndex: 999 }}
                link={coin.link}
              />

              {index === array.length - 1 ? (
                <CreatorItem
                  sx={{
                    opacity: 0.5,
                    position: "absolute",
                    ml: "-40vw",
                    transform: "scale(0.95)",
                  }}
                  title={array[0].name}
                  coin={array[0].image}
                  reward={array[0].reward}
                  link={coin.link}
                />
              ) : (
                <CreatorItem
                  sx={{
                    opacity: 0.5,
                    position: "absolute",
                    ml: "-40vw",
                    transform: "scale(0.95)",
                  }}
                  title={array[index + 1].name}
                  coin={array[index + 1].image}
                  reward={array[index + 1].reward}
                  link={coin.link}
                />
              )}
            </Grid>
          </Grid>
        ))}
      </Carousel>
    </Container>
  );
}
