import { SvgIcon } from "@mui/material";
import { TSocialIcons } from "../types";

export default function MediumFooter({ hover, ...props }: TSocialIcons) {
  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <g id="fb" transform="translate(0 -0.247)">
        <rect
          id="fb_style_"
          data-name="fb (style)"
          width="32"
          height="32"
          rx="16"
          transform="translate(0 0.247)"
          fill={hover ? "#e6e8ec" : "#141416"}
        />
        <path
          id="Icon_awesome-medium"
          data-name="Icon awesome-medium"
          d="M0,2.25V14.666H12.416V2.25ZM10.316,5.19l-.666.638a.2.2,0,0,0-.075.186v4.692a.191.191,0,0,0,.075.186l.651.636v.142H7.031v-.135L7.7,10.88c.066-.066.066-.086.066-.186V6.9L5.9,11.659H5.645L3.465,6.9v3.185a.438.438,0,0,0,.121.366l.875,1.064v.142H1.973v-.142l.876-1.059a.423.423,0,0,0,.112-.366V6.4a.317.317,0,0,0-.105-.272L2.075,5.19V5.049H4.5l1.868,4.1,1.643-4.1h2.309Z"
          transform="translate(10 7.997)"
          fill={hover ? "#141416" : "#e6e8ec"}
        />
      </g>
    </SvgIcon>
  );
}
