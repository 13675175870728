/* eslint-disable react/jsx-props-no-spreading */
import { SvgIcon, SvgIconProps } from "@mui/material";

export default function Logo(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 156.547 36.278" {...props}>
      <g id="_2" data-name="2" transform="translate(-4479.659 3516.431)">
        <path
          id="Path_43753"
          data-name="Path 43753"
          d="M19.952,9.111c-.642.335-1.279.669-1.905,1s-1.265.662-1.915.984l-2.75-4.06-1.305.655,1.781,2.944-3.195,1.636L8.77,9.367l-1.5.774L8.75,12.593,9.8,14.331c-.748.4-1.494.794-2.233,1.182S6.088,16.3,5.349,16.7L0,8.3,14.439,0Z"
          transform="matrix(0.743, -0.669, 0.669, 0.743, 4610.205, -3492.565)"
          fill="#fff"
        />
        <path
          id="Path_43745"
          data-name="Path 43745"
          d="M10.563,1a7.012,7.012,0,0,1,1.1.986,4.5,4.5,0,0,1,.714,1.074,4.305,4.305,0,0,1,.356,1.233,6.842,6.842,0,0,1,.037,1.46l5.017.624-2.042,4.309L11.231,9.342,10.5,10.4l3.371,2.717-2.653,3.6q-1.877-1.448-3.743-2.88T3.743,10.951L1.858,9.51Q.924,8.8,0,8.038A13.176,13.176,0,0,1,.492,6.617,13.443,13.443,0,0,1,1.11,5.311q.344-.632.75-1.243t.864-1.231A7.987,7.987,0,0,1,4.347,1.185,5.977,5.977,0,0,1,6.3.191,4.949,4.949,0,0,1,8.426.059,4.82,4.82,0,0,1,10.563,1Zm-2.5,3.948a2.5,2.5,0,0,0-.715-.38,1.779,1.779,0,0,0-.716-.089,1.62,1.62,0,0,0-.678.222,2.072,2.072,0,0,0-.607.567,3.507,3.507,0,0,0-.219.342,2.831,2.831,0,0,0-.166.356L7.98,8.391l.147-.2a3.861,3.861,0,0,0,.449-.8,2.777,2.777,0,0,0,.209-.879,1.975,1.975,0,0,0-.137-.851A1.632,1.632,0,0,0,8.066,4.943Z"
          transform="translate(4614.604 -3505.915) rotate(69)"
          fill="#fff"
        />
        <path
          id="Path_43752"
          data-name="Path 43752"
          d="M11.559,2.708l-8.3,9.937L0,10.495,7.448,0Z"
          transform="translate(4593.229 -3500.616) rotate(-22)"
          fill="#fff"
        />
        <path
          id="Path_43751"
          data-name="Path 43751"
          d="M12.26,16.1l-4.78-1.588L7.922,12.2l-2.068-1L4.412,13.034,0,10.35,9.436,0,14.6,2.177ZM8.935,9.263l.831-3.491L7.572,8.607Z"
          transform="matrix(0.966, -0.259, 0.259, 0.966, 4580.723, -3504.045)"
          fill="#fff"
        />
        <path
          id="Path_43750"
          data-name="Path 43750"
          d="M15.976,2.632l-3.369,12.9-5.135-.572L6.211,7.426,4.326,14.458,0,13.571,3.142,0,8,.755l1.906,7.61,1.5-6.509Z"
          transform="translate(4568.805 -3508.572) rotate(-3)"
          fill="#fff"
        />
        <path
          id="Path_43748"
          data-name="Path 43748"
          d="M258.678,187.652a9.733,9.733,0,0,1-.172,2.323,9.108,9.108,0,0,1-.7,2.122,8.771,8.771,0,0,1-1.182,1.842,8.108,8.108,0,0,1-5.894,2.887,8.28,8.28,0,0,1-2.271-.2,8.6,8.6,0,0,1-2.047-.757,8.186,8.186,0,0,1-3.121-2.894,8.858,8.858,0,0,1-.921-1.983,8.332,8.332,0,0,1-.4-2.239,9.112,9.112,0,0,1,.175-2.256,8.983,8.983,0,0,1,1.875-3.959,8.283,8.283,0,0,1,1.589-1.483,7.949,7.949,0,0,1,1.938-1.012,7.6,7.6,0,0,1,2.239-.432,9.816,9.816,0,0,1,3.508.433,7.37,7.37,0,0,1,4.609,4.151A9.5,9.5,0,0,1,258.678,187.652Zm-5.831.607a3.7,3.7,0,0,0-.217-1.092,3,3,0,0,0-.523-.926,2.5,2.5,0,0,0-.814-.637,2.218,2.218,0,0,0-1.085-.207,2.592,2.592,0,0,0-1.1.275,2.5,2.5,0,0,0-.807.65,2.773,2.773,0,0,0-.494.933,3.364,3.364,0,0,0-.132,1.106,3.984,3.984,0,0,0,.207,1.1,3.307,3.307,0,0,0,.521.979,2.514,2.514,0,0,0,.817.688,2.078,2.078,0,0,0,1.1.228,2.459,2.459,0,0,0,1.1-.294,2.5,2.5,0,0,0,.807-.7,3.057,3.057,0,0,0,.485-.977A3.552,3.552,0,0,0,252.847,188.259Z"
          transform="translate(4311.309 -3691.294)"
          fill="#fff"
        />
        <path
          id="Path_43749"
          data-name="Path 43749"
          d="M13.24,0l1.278,5.186L10.935,6.313l2.2,11.773L8.081,19.743,4.981,8.216,1.411,9.395,0,3.618Z"
          transform="matrix(0.966, 0.259, -0.259, 0.966, 4542.434, -3515.859)"
          fill="#fff"
        />
        <path
          id="Path_43747"
          data-name="Path 43747"
          d="M13.475,2.772a5.239,5.239,0,0,1,.6,1.831,4.615,4.615,0,0,1-.1,1.693A5.151,5.151,0,0,1,13.3,7.834,8.139,8.139,0,0,1,12.21,9.207a10.736,10.736,0,0,1-1.385,1.179,13.921,13.921,0,0,1-1.532.963l2.4,4.559-4.557,2.4Q5.957,16.07,4.8,13.843T2.467,9.348q-.61-1.2-1.247-2.392T0,4.523A20.484,20.484,0,0,1,2.211,2.646,19.609,19.609,0,0,1,4.693,1.1,10.5,10.5,0,0,1,6.265.429,8.255,8.255,0,0,1,7.912.054,7.2,7.2,0,0,1,9.541.039a5.02,5.02,0,0,1,1.521.405,4.968,4.968,0,0,1,1.336.889A5.284,5.284,0,0,1,13.475,2.772ZM9.308,5.393A1.464,1.464,0,0,0,8.4,4.6a1.613,1.613,0,0,0-1.192.172,3.2,3.2,0,0,0-.406.258c-.136.1-.258.2-.372.3L7.815,8.255a2.246,2.246,0,0,0,.273-.119L8.355,8a2.249,2.249,0,0,0,.617-.476,2.053,2.053,0,0,0,.4-.644,1.946,1.946,0,0,0,.133-.733A1.675,1.675,0,0,0,9.308,5.393Z"
          transform="matrix(0.94, 0.342, -0.342, 0.94, 4530.516, -3513.038)"
          fill="#fff"
        />
        <path
          id="Path_43746"
          data-name="Path 43746"
          d="M11.257,0l.994,10.789,4.473,5.624-4.113,3.557L8.046,14.485,0,10.2,3.94,5.814,7.878,8.96,6.837,3.307Z"
          transform="translate(4521.362 -3516.431) rotate(31)"
          fill="#fff"
        />
        <path
          id="Path_43744"
          data-name="Path 43744"
          d="M12.113,1.142a8.041,8.041,0,0,1,1.263,1.131A5.158,5.158,0,0,1,14.194,3.5,4.937,4.937,0,0,1,14.6,4.917a7.846,7.846,0,0,1,.042,1.674l5.753.715-2.341,4.941-5.177-1.535-.838,1.218,3.866,3.116-3.043,4.126q-2.152-1.661-4.292-3.3t-4.28-3.312L2.13,10.906Q1.06,10.089,0,9.217A15.109,15.109,0,0,1,.564,7.588a15.415,15.415,0,0,1,.709-1.5q.395-.725.86-1.426t.99-1.412A9.159,9.159,0,0,1,4.984,1.359,6.854,6.854,0,0,1,7.222.219,5.675,5.675,0,0,1,9.662.068,5.527,5.527,0,0,1,12.113,1.142ZM9.249,5.668a2.866,2.866,0,0,0-.82-.435,2.04,2.04,0,0,0-.821-.1,1.857,1.857,0,0,0-.778.254,2.376,2.376,0,0,0-.7.651,4.021,4.021,0,0,0-.251.392,3.246,3.246,0,0,0-.19.408L9.151,9.622l.169-.23a4.427,4.427,0,0,0,.515-.917,3.184,3.184,0,0,0,.239-1.008,2.265,2.265,0,0,0-.157-.975A1.872,1.872,0,0,0,9.249,5.668Z"
          transform="matrix(0.719, 0.695, -0.695, 0.719, 4510.164, -3512.791)"
          fill="#fff"
        />
        <g
          id="Group_17314"
          data-name="Group 17314"
          transform="matrix(0.995, -0.105, 0.105, 0.995, 4479.659, -3512.299)"
        >
          <path
            id="Path_42020"
            data-name="Path 42020"
            d="M1.068,30.394,0,25.963a14.364,14.364,0,0,1,2.1-1.615c.959-.552,1.991-1.135,1.991-1.135S1.169,11.743,1.008,11.437,4.09,8.953,4.09,8.953,2.384,5.812,2.668,5.586s3.8-1.245,3.8-1.245.818,1,.952.845.559-.294.691-.5.183-2.913.528-3.1S12.182,0,12.182,0l3.468,1.5.611,2.988a.264.264,0,0,0,.305-.147c.135-.21,1.179-1.567,1.179-1.567L21.814,3.91l-.3,2.5.219,4.061-10,2.143-.4-2.4-4.524,2.4,4.258,7.607,3.706-1.639v-3.23l7.194-1.429,1.036,9.941s-1.439.65-1.511.892.225.923.126.953a60.755,60.755,0,0,0-12.019,1A24.962,24.962,0,0,0,1.068,30.394Z"
            transform="translate(0 0)"
            fill="#fff"
          />
          <g
            id="Group_16489"
            data-name="Group 16489"
            transform="translate(1.763 0.569)"
            opacity="0.03"
          >
            <path
              id="Path_42021"
              data-name="Path 42021"
              d="M0,0,4.313,8.071l-1.9,1.27Z"
              transform="translate(0 13.161)"
              fill="none"
            />
            <path
              id="Path_42022"
              data-name="Path 42022"
              d="M0,0,1.27,2.591,4.734.476Z"
              transform="translate(1.52 5.53)"
              fill="none"
            />
            <path
              id="Path_42023"
              data-name="Path 42023"
              d="M0,0C-.016,0,.858,1.853.858,1.853L4.06,0Z"
              transform="translate(4.313 10.094)"
              fill="none"
            />
            <path
              id="Path_42024"
              data-name="Path 42024"
              d="M0,.714A11.3,11.3,0,0,0,2.036,0L1.377,2.964Z"
              transform="translate(9.377 19.062)"
              fill="none"
            />
            <path
              id="Path_42025"
              data-name="Path 42025"
              d="M0,2.766l4.551-1.44L0,0Z"
              transform="translate(13.186 15.066)"
              fill="none"
            />
            <path
              id="Path_42026"
              data-name="Path 42026"
              d="M.5,4.047c.373-.159,1.746-1.059,1.746-1.059L0,0Z"
              transform="translate(19.008 19.379)"
              fill="none"
            />
            <path
              id="Path_42027"
              data-name="Path 42027"
              d="M0,2.015,5.4,0,2.573,3.5l-2.28.672Z"
              transform="translate(9.741 7.582)"
              fill="none"
            />
            <path
              id="Path_42028"
              data-name="Path 42028"
              d="M0,3.381.268,1.6,3.508,0Z"
              transform="translate(6.734 0)"
              fill="none"
            />
            <path
              id="Path_42029"
              data-name="Path 42029"
              d="M3.508,3.381,3.24,1.6,0,0Z"
              transform="translate(10.518 0)"
              fill="none"
            />
            <path
              id="Path_42030"
              data-name="Path 42030"
              d="M0,6.013,2.036,4.682,2.484,0l.629,5.372Z"
              transform="translate(17.011 4.081)"
              fill="none"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
