import { AppBar, Toolbar, useMediaQuery } from "@mui/material";
import { Trans } from "react-i18next";
import theme from "utils/theme";
import { useState } from "react";
import Grid from "../styled/Grid";
import { AppDrawer, LogoSection, MobileTopBar, TopBarLink } from "./components";
import DownloadDialog from "./components/DownloadDialog/DownloadDialog";

export const sections = [
  {
    title: <Trans i18nKey="about.title" />,
    to: "#about",
  },
  {
    title: <Trans i18nKey="features.title" />,
    to: "#features",
  },
  {
    title: <Trans i18nKey="supporters.title" />,
    to: "#supporters",
  },
  {
    title: <Trans i18nKey="contact.title" />,
    to: "#contact",
  },
];

export default function NavigationBar() {
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = useState<boolean>(false);
  const [currentLink, setCurrentLink] = useState<string>("about");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const toggleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(open);
    };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          borderBottom: {
            xs: `1px solid ${theme.palette.secondary.light}`,
            lg: "1px solid rgba(188, 190, 192, 0.1)",
          },
        }}
      >
        <Toolbar disableGutters={mobile}>
          <Grid
            container
            display={{ xs: "none", lg: "flex" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid container item justifyContent="space-around" md={2} order={0}>
              {sections.slice(0, 2).map((section) => (
                <Grid item key={`section:${section.to}`}>
                  <TopBarLink
                    to={section.to}
                    title={section.title}
                    onClick={() => setCurrentLink(section.to)}
                    onSetActive={() => setCurrentLink(section.to)}
                    active={currentLink === section.to}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid container item justifyContent="space-evenly" md={2} order={2}>
              {sections.slice(2, sections.length + 1).map((section) => (
                <Grid item key={`link:${section.to}`}>
                  <TopBarLink
                    key={`section:${section.to}`}
                    to={section.to}
                    title={section.title}
                    onClick={() => setCurrentLink(section.to)}
                    onSetActive={() => setCurrentLink(section.to)}
                    active={currentLink === section.to}
                  />
                </Grid>
              ))}
            </Grid>
            <LogoSection order={1} />
          </Grid>
          <MobileTopBar
            onGetAppClicked={() => setOpenDialog(true)}
            onMenuClicked={() => setOpen(true)}
          />
        </Toolbar>
      </AppBar>

      {open ? (
        <AppDrawer
          onClosedClicked={() => setOpen(false)}
          onLinkClicked={() => {
            setOpen(false);
          }}
          open={open}
          onClose={toggleDrawer()}
          passedLink={currentLink}
        />
      ) : null}
      <DownloadDialog open={openDialog} onClose={() => setOpenDialog(false)} />
    </>
  );
}
