import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SectionHeader from "modules/common/components/SectionHeader/SectionHeader";
import { ArrowRight, StarLine } from "modules/common/icons";
import { useTranslation } from "react-i18next";
import { enrollText } from "modules/home/components/Features/Components/Enroll/constants";
import MockupImage from "modules/home/components/Features/Components/Enroll/components/MockupImage";
import theme from "utils/theme";
import { CustomLink } from "modules/common/components";

export default function ProcessingCard() {
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={{ xs: "center", md: "flex-start" }}
      justifyContent="space-evenly"
      pb={2}
    >
      <Stack
        width={{ xs: "90vw", md: "30vw" }}
        textAlign={{ xs: "center", md: "left" }}
      >
        <SectionHeader
          title={enrollText.title}
          subtitle={enrollText.subtitle}
          content={enrollText.content}
          alignItems={mobile ? "center" : "flex-start"}
        />
      </Stack>

      <MockupImage display={{ xs: "flex", md: "none" }} />

      <Box
        sx={{
          width: {
            xs: "300px",
            sm: "50vw",
            md: "30vw",
            lg: "330px",
            xl: "380px",
          },
          borderRadius: "16px",
          backgroundColor: theme.palette.background.paper,
          display: "flex",
          p: 2,
          mt: { xs: 5, md: 2 },
          mb: { xs: 2, md: 0 },
        }}
      >
        <Grid container flex={1} rowGap={1}>
          <Grid item xs={7}>
            <Typography variant="h3">
              {t("features.getYourCreatorPass")}
            </Typography>
          </Grid>
          <Grid item xs={9} md={8}>
            <CustomLink to="#contact">
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: theme.palette.success.dark,
                  mt: 2,
                  py: 1.2,
                  boxShadow: 0,
                }}
              >
                <Typography
                  variant="subtitle2"
                  color={theme.palette.primary.dark}
                  sx={{ cursor: "inherit" }}
                >
                  {t("features.applyForCreatorButtonTitle")}
                </Typography>
                <ArrowRight
                  sx={{
                    fontSize: { xs: "3vw", lg: "1vw", xl: "0.8vw" },
                    ml: 1,
                  }}
                />
              </Button>
            </CustomLink>
          </Grid>
        </Grid>
        <Box>
          <StarLine
            sx={{
              fontSize: { xs: "35vw", sm: "20vw", md: "9vw", xl: "7vw" },
              width: "auto",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
