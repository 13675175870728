/* eslint-disable react/jsx-props-no-spreading */
import { SvgIcon, SvgIconProps } from "@mui/material";

export default function LeaderboardIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 23.937 23.937" {...props}>
      <rect
        id="Icon"
        width="23"
        height="23"
        transform="translate(0.453 0.453)"
        fill="none"
      />
      <path id="Vector" d="M0,0H23.937V23.937H0Z" fill="none" />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M1,0h3.99a1,1,0,0,1,1,1V6.982a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0Z"
        transform="translate(2.992 11.969)"
        fill="none"
        stroke="#777e90"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M1,0h3.99a1,1,0,0,1,1,1v9.974a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0Z"
        transform="translate(14.961 7.979)"
        fill="none"
        stroke="#777e90"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Vector-4"
        data-name="Vector"
        d="M1,0h3.99a1,1,0,0,1,1,1V14.961a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0Z"
        transform="translate(8.976 3.99)"
        fill="none"
        stroke="#777e90"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Vector-5"
        data-name="Vector"
        d="M0,0H13.963"
        transform="translate(3.99 19.948)"
        fill="none"
        stroke="#777e90"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
