/* eslint-disable react/jsx-props-no-spreading */
import { SvgIcon, SvgIconProps } from "@mui/material";

export default function StarIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 18.782 18.057" {...props}>
      <path
        id="Disabled"
        d="M8.921.557,6.875,4.7,2.3,5.371a1,1,0,0,0-.552,1.705l3.308,3.23-.783,4.556a1,1,0,0,0,1.449,1.055l4.092-2.151,4.092,2.151a1,1,0,0,0,1.449-1.055l-.783-4.556,3.318-3.23a1,1,0,0,0-.554-1.71L12.764,4.7,10.718.557a1,1,0,0,0-1.8,0Z"
        transform="translate(-0.432 1.001)"
        fill="none"
        stroke="#777e90"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
