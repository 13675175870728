import { Grid, GridProps } from "@mui/material";
import { mockup, mockupBackground } from "assets";
import { Image } from "modules/common/components";

export default function MockupImage({ ...props }: GridProps) {
  return (
    <Grid
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      container
      item
      xs={12}
      md={6}
      sx={{
        background: `transparent url(${mockupBackground}) no-repeat center`,
        backgroundSize: { xs: "50%", sm: "35%", md: "45%", lg: "40%" },
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid item xs={11} sm={8} md={10} textAlign="center">
        <Image
          src={mockup}
          alt="mock image"
          width="100%"
          height="auto"
          effect="opacity"
        />
      </Grid>
    </Grid>
  );
}
