import { Button, Grid, Typography } from "@mui/material";
import { gridBackground, gridWithoutQR, iphone } from "assets";
import { DownloadApp, Image } from "modules/common/components";
import { ArrowDown, GroupedPepper, PhoneIcon } from "modules/common/icons";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import theme from "utils/theme";

type TAppDemo = {
  onClick: () => void;
};

export default function AppDemo({ onClick }: TAppDemo) {
  const { t } = useTranslation();
  return (
    <Grid container justifyContent="center" mt={{ md: -6, xl: -3 }}>
      <Grid container item alignItems="center" justifyContent="center">
        <Grid
          container
          item
          position="relative"
          sx={{
            fontFamily: "Rubik",
            background: {
              xs: `transparent url(${gridWithoutQR}) no-repeat center`,
              sm: `transparent url(${gridBackground}) no-repeat center`,
            },
            backgroundSize: { xs: "90%", sm: "80%", lg: "70%", xl: "60%" },
          }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <GroupedPepper
              sx={{
                fontSize: {
                  xs: "90vw",
                  sm: "50vw",
                  md: "50vw",
                  lg: "45vw",
                  xl: "35vw",
                },
                position: "sticky",
                zIndex: 9,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            xl={5}
            justifyContent="center"
            alignItems="center"
            position="absolute"
          >
            <Image
              src={iphone}
              style={{
                maxWidth: "82%",
                height: "auto",
                marginLeft: "25px",
              }}
              alt="iphone"
            />
          </Grid>
        </Grid>

        <ArrowDown
          sx={{
            display: { xs: "none", md: "block" },
            fontSize: "13rem",
            width: "auto",
            position: "relative",
            bottom: { md: "32vw", lg: "28vw" },
            left: { md: "43vw", lg: "40vw" },
            animation: "1.5s move infinite !important",
            "@keyframes move": {
              "0%": {
                opacity: 0.3,
              },
              "50%": {
                opacity: 1,
              },
              "100%": {
                opacity: 0.3,
              },
            },
          }}
        />
      </Grid>
      <Grid
        container
        item
        xs={11}
        md={6}
        justifyContent="center"
        textAlign="center"
        position="relative"
        top={{ xs: 0, md: -200, lg: -120 }}
      >
        <Grid item>
          <Typography variant="h3" mb={3}>
            <Trans
              i18nKey={t("about.areYouCryptoGeek")}
              components={{
                bold: <strong style={{ color: theme.palette.success.dark }} />,
              }}
            />
          </Typography>
        </Grid>
        {/* Show get the app button for web */}
        <Grid item display={{ xs: "none", md: "block" }}>
          <Button
            variant="contained"
            size="large"
            sx={{
              textTransform: "none",
              border: "none",
              backgroundColor: theme.palette.success.dark,
            }}
            onClick={onClick}
          >
            <Typography
              variant="subtitle2"
              color={theme.palette.info.dark}
              sx={{ cursor: "inherit" }}
            >
              {t("about.getTheApp")}
            </Typography>
            <PhoneIcon />
          </Button>
        </Grid>
        {/* show download buttons play store and app store for mobile view */}
        <Grid item display={{ xs: "block", md: "none" }}>
          <DownloadApp />
        </Grid>
      </Grid>
    </Grid>
  );
}
