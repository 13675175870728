/* eslint-disable react/jsx-props-no-spreading */
import { SvgIcon, SvgIconProps } from "@mui/material";

export default function CupLine(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 15.913 13.02" {...props}>
      <path
        id="Shape"
        d="M5.063,10.126A5.069,5.069,0,0,1,0,5.063V2.17A2.172,2.172,0,0,1,2.17,0H7.956a2.172,2.172,0,0,1,2.17,2.17V5.063A5.069,5.069,0,0,1,5.063,10.126ZM2.17,1.446a.724.724,0,0,0-.724.724V5.063a3.617,3.617,0,0,0,7.234,0V2.17a.724.724,0,0,0-.724-.724Z"
        transform="translate(2.893 0)"
        fill="#fcfcfd"
      />
      <g id="Shape-2" data-name="Shape" transform="translate(0 1.447)">
        <path
          id="F0E5246D-AA31-4CEC-A44D-748D124F7E62"
          d="M4.34,5.787H2.893A2.9,2.9,0,0,1,0,2.893V2.17A2.172,2.172,0,0,1,2.17,0H4.34V5.786ZM2.17,1.446a.724.724,0,0,0-.724.724v.724A1.448,1.448,0,0,0,2.893,4.34V1.446Z"
          transform="translate(0 0)"
          fill="#fcfcfd"
        />
        <path
          id="_2DD86539-FDC6-4059-BAB0-546B0CCA538A"
          data-name="2DD86539-FDC6-4059-BAB0-546B0CCA538A"
          d="M1.446,5.787H0V0H2.17A2.172,2.172,0,0,1,4.34,2.17v.724A2.9,2.9,0,0,1,1.446,5.787Zm0-4.34V4.34A1.449,1.449,0,0,0,2.893,2.893V2.17a.724.724,0,0,0-.724-.724Z"
          transform="translate(11.573 0)"
          fill="#fcfcfd"
        />
      </g>
      <path
        id="Shape-3"
        data-name="Shape"
        d="M2.893,0A.723.723,0,0,0,2.17.723v2.17H.723a.723.723,0,1,0,0,1.447h4.34a.723.723,0,0,0,0-1.447H3.617V.723A.723.723,0,0,0,2.893,0Z"
        transform="translate(5.063 8.68)"
        fill="#fcfcfd"
      />
    </SvgIcon>
  );
}
