/* eslint-disable react/jsx-props-no-spreading */
import { SvgIcon, SvgIconProps } from "@mui/material";

export default function ArrowRight(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 14 9" {...props}>
      <g
        id="icons_Arrow_Right_2_Line"
        data-name="icons/Arrow Right 2/Line"
        transform="translate(-5 -7)"
      >
        <path
          id="vector_Stroke_"
          data-name="vector (Stroke)"
          d="M9.091.265A1,1,0,0,1,10.5.322l3.231,3.5a1,1,0,0,1,0,1.357L10.5,8.678a1,1,0,1,1-1.47-1.357L10.716,5.5H1a1,1,0,0,1,0-2h9.716L9.034,1.678A1,1,0,0,1,9.091.265Z"
          transform="translate(5 7)"
          fill="#141517"
        />
      </g>
    </SvgIcon>
  );
}
